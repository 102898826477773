import React from 'react';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import rtlDetect from 'rtl-detect';

import { LoadStepComponent as LoadStep } from './LoadStepComponent';
import { CustomCssWrapperComponent as CustomCssWrapper } from './CustomCssWrapperComponent';
import { CustomTextWrapper } from './CustomTextWrapper';
import { TestModeFlagComponent } from './TestModeFlag/TestModeFlagComponent';
import { getCustomCss } from '../lib/ProgramTheme/programThemeGetters';
import { getOptionsMessages, modernizeLocale } from '../lib/intl/intl';
import { ProgramTheme, VerificationService } from '../lib/types/types';
import { includeIPQSDeviceFingerprintScript } from '../lib/IPQS/IPQSutils';

export const NewVerificationInnerComponent = ({
    locale, messages, programTheme, verificationService,
}: {
    // TODO better types:
    locale: any; messages: any; programTheme: ProgramTheme; verificationService: VerificationService;
}) => {
    React.useEffect(() => {
        includeIPQSDeviceFingerprintScript(programTheme, verificationService.verificationResponse.verificationId);
    }, [verificationService.verificationResponse.verificationId]);

    const messagesWithOptions = { ...messages, ...flatten(getOptionsMessages(locale)) as Object };

    return (
        // Don't change locale, so we don't have to load pluralization data, which has complications. HD-560
        <IntlProvider locale="en" key={locale} messages={messagesWithOptions} textComponent={CustomTextWrapper}>
            <CustomCssWrapper styles={getCustomCss(programTheme)}>
                <div className={rtlDetect.isRtlLang(modernizeLocale(locale)) ? 'sid-rtl' : 'sid-ltr'}>
                    <TestModeFlagComponent verificationService={verificationService} />
                    <LoadStep stepToLoad={verificationService.verificationResponse.currentStep} verificationService={verificationService} />
                </div>
            </CustomCssWrapper>
        </IntlProvider>
    );
};
