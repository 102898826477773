import { DatabaseId } from '../../types/types';
import { logger } from '../logger/logger';

export const ensureTrailingSlash = (url: string) => url.replace(/\/?$/, '/');

export const getQueryParamsFromUrl = (url?: string) => {
    const newUrl = new URL(url || window.location.toString());
    return new URLSearchParams(newUrl.search.slice(1));
};

export const getVerificationIdFromQueryString = (queryString: string): DatabaseId => {
    const verificationIdQueryParameter: string = 'verificationId';
    const queryStringParameters: URLSearchParams = new URLSearchParams(queryString);
    const verificationId: DatabaseId = queryStringParameters.get(verificationIdQueryParameter);
    if (typeof verificationId === 'string' && verificationId.length === 0) {
        return null;
    }
    return verificationId;
};

export const getTrackingIdFromQueryString = (queryString: string): string => {
    const trackingIdQueryParameter: string = 'trackingId';
    const queryStringParameters: URLSearchParams = new URLSearchParams(queryString);
    const trackingId: DatabaseId = queryStringParameters.get(trackingIdQueryParameter);
    if (typeof trackingId === 'string' && trackingId.length === 0) {
        return null;
    }
    return trackingId;
};

export const getDomainFromUrl = (url: string) => {
    let domain = url;
    try {
        domain = new URL(url).hostname;
    } catch (e) {
        logger.warn(e);
    }
    return domain.replace(/(www\.)?/, '');
};


export const redirectTo = (path: string) => {
    document.location.href = path;
};

