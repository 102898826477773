import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FormSelectChoice, InputSelectComponentProps } from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';

interface StatusComponentProps extends InputSelectComponentProps {
    segmentId: string;
}

export const Status = ({
    value,
    isErrored,
    options,
    onChange,
    intl,
    placeholder = '',
    onKeyDown = undefined,
    segmentId = '',
}: StatusComponentProps) => {
    const id = `sid-${segmentId}-status`;

    return (
        <div className={`sid-field sid-${segmentId}-status`}>
            <div className="sid-l-space-top-md" />
            <label htmlFor={id}>
                <div id={`${id}-label`} className={`sid-field__label sid-field__label--required sid-${segmentId}-status__label sid-l-space-btm-sm`}>
                    <FormattedHTMLMessage
                        id="status"
                        defaultMessage="Status"
                    />
                </div>
            </label>
            <InputSelectComponent
                fieldId="status"
                inputId={id}
                isErrored={isErrored}
                options={options}
                onChange={(status: FormSelectChoice) => onChange(status)}
                onKeyDown={onKeyDown}
                value={value}
                placeholder={placeholder || intl.formatMessage({ id: 'statusPlaceholder', defaultMessage: 'Status*' })}
                isRequired
            />
            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidStatus"
                            defaultMessage="Invalid status"
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

export const StatusComponent = injectIntl(Status);
