import { flatten } from 'flat';

import {
    Locale,
    StringMap,
    ProgramTheme,
    DefaultMessages,
    ProgramThemeMessages,
    SegmentSpecificMessages,
    Segment,
    ViewModel,
    Country,
    MessagesModule,
    RequestOrganizationMessages,
    RequestOrganizationViewModel,
    VerificationResponse,
} from '../types/types';
import { assertValidLocale, isValidLocale } from '../types/assertions';
import { getOptions } from '../../options/options';
import { logger } from '../utils/logger/logger';
import { DEFAULT_LOCALE } from '../../constants';
import { getSafe } from '../utils/objects';

import enUSMessages from './localized-messages/en-US.json';
import { getQueryParamsFromUrl } from '../utils/routing/Url';
import { getSheerIdScriptBasePath } from '../ServerApi/ApiUrls';

const prepareMsgsModule = (localizedMessages: StringMap): MessagesModule => {
    const nonEmptyMessages = Object.keys(localizedMessages).reduce((messages, currentKey) => {
        if (localizedMessages[currentKey]) {
            messages[currentKey] = localizedMessages[currentKey]; // eslint-disable-line
        }
        return messages;
    }, {});

    return flatten.unflatten(nonEmptyMessages);
};

/**
 * @private
 * NOTE: webpack will not import chunks recursively, or with more than one variable in the path.
 * Here we're using a fully statically-defined import, for reliability.
 */
export const getMessagesModuleByLocale = async (locale: Locale): Promise<MessagesModule> => {
    let localizedMessages;

    /* tslint:disable:space-in-parens */
    switch (locale) {
        case 'ar':
            localizedMessages = await import(/* webpackChunkName: "messages_ar" */ './localized-messages/ar.json').then(m => m.default);
            break;
        case 'bg':
            localizedMessages = await import(/* webpackChunkName: "messages_bg" */ './localized-messages/bg.json').then(m => m.default);
            break;
        case 'cs':
            localizedMessages = await import(/* webpackChunkName: "messages_cs" */ './localized-messages/cs.json').then(m => m.default);
            break;
        case 'da':
            localizedMessages = await import(/* webpackChunkName: "messages_da" */ './localized-messages/da.json').then(m => m.default);
            break;
        case 'de':
            localizedMessages = await import(/* webpackChunkName: "messages_de" */ './localized-messages/de.json').then(m => m.default);
            break;
        case 'el':
            localizedMessages = await import(/* webpackChunkName: "messages_el" */ './localized-messages/el.json').then(m => m.default);
            break;
        case 'en-GB':
            localizedMessages = await import(/* webpackChunkName: "messages_en-GB" */ './localized-messages/en-GB.json').then(m => m.default);
            break;
        case 'en-US':
            localizedMessages = enUSMessages;
            break;
        case 'es-ES':
            localizedMessages = await import(/* webpackChunkName: "messages_es-ES" */ './localized-messages/es-ES.json').then(m => m.default);
            break;
        case 'es':
            localizedMessages = await import(/* webpackChunkName: "messages_es" */ './localized-messages/es.json').then(m => m.default);
            break;
        case 'fi':
            localizedMessages = await import(/* webpackChunkName: "messages_fi" */ './localized-messages/fi.json').then(m => m.default);
            break;
        case 'fr-CA':
            localizedMessages = await import(/* webpackChunkName: "messages_fr-CA" */ './localized-messages/fr-CA.json').then(m => m.default);
            break;
        case 'fr':
            localizedMessages = await import(/* webpackChunkName: "messages_fr" */ './localized-messages/fr.json').then(m => m.default);
            break;
        case 'ga':
            localizedMessages = await import(/* webpackChunkName: "messages_ga" */ './localized-messages/ga.json').then(m => m.default);
            break;
        case 'hr':
            localizedMessages = await import(/* webpackChunkName: "messages_hr" */ './localized-messages/hr.json').then(m => m.default);
            break;
        case 'hu':
            localizedMessages = await import(/* webpackChunkName: "messages_hu" */ './localized-messages/hu.json').then(m => m.default);
            break;
        case 'id':
            localizedMessages = await import(/* webpackChunkName: "messages_id" */ './localized-messages/id.json').then(m => m.default);
            break;
        case 'it':
            localizedMessages = await import(/* webpackChunkName: "messages_it" */ './localized-messages/it.json').then(m => m.default);
            break;
        case 'iw':
            localizedMessages = await import(/* webpackChunkName: "messages_iw" */ './localized-messages/iw.json').then(m => m.default);
            break;
        case 'ja':
            localizedMessages = await import(/* webpackChunkName: "messages_ja" */ './localized-messages/ja.json').then(m => m.default);
            break;
        case 'ko':
            localizedMessages = await import(/* webpackChunkName: "messages_ko" */ './localized-messages/ko.json').then(m => m.default);
            break;
        case 'lo':
            localizedMessages = await import(/* webpackChunkName: "messages_lo" */ './localized-messages/lo.json').then(m => m.default);
            break;
        case 'lt':
            localizedMessages = await import(/* webpackChunkName: "messages_lt" */ './localized-messages/lt.json').then(m => m.default);
            break;
        case 'ms':
            localizedMessages = await import(/* webpackChunkName: "messages_ms" */ './localized-messages/ms.json').then(m => m.default);
            break;
        case 'nl':
            localizedMessages = await import(/* webpackChunkName: "messages_nl" */ './localized-messages/nl.json').then(m => m.default);
            break;
        case 'no':
            localizedMessages = await import(/* webpackChunkName: "messages_no" */ './localized-messages/no.json').then(m => m.default);
            break;
        case 'pl':
            localizedMessages = await import(/* webpackChunkName: "messages_pl" */ './localized-messages/pl.json').then(m => m.default);
            break;
        case 'pt-BR':
            localizedMessages = await import(/* webpackChunkName: "messages_pt-BR" */ './localized-messages/pt-BR.json').then(m => m.default);
            break;
        case 'pt':
            localizedMessages = await import(/* webpackChunkName: "messages_pt" */ './localized-messages/pt.json').then(m => m.default);
            break;
        case 'ru':
            localizedMessages = await import(/* webpackChunkName: "messages_ru" */ './localized-messages/ru.json').then(m => m.default);
            break;
        case 'sk':
            localizedMessages = await import(/* webpackChunkName: "messages_sk" */ './localized-messages/sk.json').then(m => m.default);
            break;
        case 'sl':
            localizedMessages = await import(/* webpackChunkName: "messages_sl" */ './localized-messages/sl.json').then(m => m.default);
            break;
        case 'sr':
            localizedMessages = await import(/* webpackChunkName: "messages_sr" */ './localized-messages/sr.json').then(m => m.default);
            break;
        case 'sv':
            localizedMessages = await import(/* webpackChunkName: "messages_sv" */ './localized-messages/sv.json').then(m => m.default);
            break;
        case 'th':
            localizedMessages = await import(/* webpackChunkName: "messages_th" */ './localized-messages/th.json').then(m => m.default);
            break;
        case 'tr':
            localizedMessages = await import(/* webpackChunkName: "messages_tr" */ './localized-messages/tr.json').then(m => m.default);
            break;
        case 'zh-HK':
            localizedMessages = await import(/* webpackChunkName: "messages_zh-HK" */ './localized-messages/zh-HK.json').then(m => m.default);
            break;
        case 'zh':
            localizedMessages = await import(/* webpackChunkName: "messages_zh" */ './localized-messages/zh.json').then(m => m.default);
            break;
        default:
            logger.warn(`getMessagesModuleByLocale: Unable to load messages for ${locale}, falling back to en-US`);
            localizedMessages = enUSMessages;
    }
    /* tslint:enable:space-in-parens */
    return prepareMsgsModule(localizedMessages);
};

const getMessagesModuleByLocaleUsingFetch = async (locale: Locale): Promise<MessagesModule> => {
    const rsp = await fetch(`${getSheerIdScriptBasePath()}localized-messages/${locale}.json`, {
        headers: {
            'Content-Type': 'text/plain',
        },
    });
    let jsonData = {};
    try {
        jsonData = await rsp.json();
    } catch (e) {
        logger.error(e);
    }
    return prepareMsgsModule(jsonData as unknown as StringMap);
};

/**
 * @description Pluck only the messages we want to override defaults with.
 * Empty string values like `""` are allowed so messages can be hidden.
 * Messages with `undefined` values are omitted.
 *
 * @private
 */
export const getThemeMessages = (msgs: ProgramThemeMessages): StringMap => {
    const flatThemeMsgs = flatten(msgs);
    const returnMsgs = {};
    Object.keys(flatThemeMsgs).forEach((key) => {
        if (typeof flatThemeMsgs[key] === 'string') {
            returnMsgs[key] = flatThemeMsgs[key];
        }
    });
    return returnMsgs;
};

export const getOptionsMessages = (locale: Locale): Object => {
    let optionalMessages: Object = {};
    if (getOptions().messagesWithLocale[locale]) {
        optionalMessages = getOptions().messagesWithLocale[locale];
    }
    return optionalMessages;
};

/**
 * @todo tests
 * @private
 */
export const countryToLocale = (country: Country): Locale => {
    let locale: Locale;
    switch (country) {
        case 'US':
            locale = 'en-US';
            break;
        default:
            locale = 'en-US';
    }
    return locale;
};

/**
 * Map old locales (Java 7) to new standards
 * @private
 */
export const modernizeLocale = (locale: Locale): string => (locale === 'iw' ? 'he' : locale); // for now only Hebrew needs to be futurized

/**
 * @description Get all messages for a given locale.
 *      Return a flattened messages object, where properties are dot-string style.
 */
export const getMessages = async (locale: Locale, programThemeMessages?: ProgramThemeMessages, segment?: Segment): Promise<StringMap> => {
    let usedLocale = locale;

    if (!(isValidLocale(usedLocale))) {
        logger.error(`Invalid locale ${usedLocale}, falling back to ${DEFAULT_LOCALE} for the verification form`, 'Invalid locale');
        usedLocale = DEFAULT_LOCALE;
    }

    let messagesModule: MessagesModule;
    if (window.sheerIdEs5 === 'true') {
        // Code splitting has complications for es5 context b/c of CDN caching, so use fetch instead.
        messagesModule = await getMessagesModuleByLocaleUsingFetch(usedLocale);
    } else {
        // Code split works fine for es6 and fetch has complications b/c localized-messages aren't copied/served from the project
        messagesModule = await getMessagesModuleByLocale(usedLocale);
    }

    const defaultMessages: DefaultMessages | object = messagesModule ? messagesModule.defaultMessages : {};
    const hasSegmentMessages = messagesModule && messagesModule.segmentMessages;
    const segmentSpecificMessages: SegmentSpecificMessages | object = segment && hasSegmentMessages ? messagesModule.segmentMessages[segment] : {};

    const optionsMessages = getOptionsMessages(usedLocale);
    const cleanFlatThemeMessages: StringMap = programThemeMessages && usedLocale === DEFAULT_LOCALE ? getThemeMessages(programThemeMessages) : {};

    // For non en-US locales, we'll merge en-US messages as a base
    const defaultLocaleMessages: StringMap = usedLocale !== DEFAULT_LOCALE ? await getMessages(DEFAULT_LOCALE, programThemeMessages, segment) : {};

    // Flatten these before object.assign so they are dot-props, so nested objects aren't overwritten, deleting properties
    const messages: StringMap = Object.assign(
        {} as StringMap,
        defaultLocaleMessages,
        defaultMessages ? flatten(defaultMessages) : {},
        segmentSpecificMessages ? flatten(segmentSpecificMessages) : {},
        cleanFlatThemeMessages,
        optionsMessages ? flatten(optionsMessages) : {},
    );

    return messages;
};

/**
 * @todo tests
 * @description Get all messages for a given locale.
 *      Return a flattened messages object, where properties are dot-string style.
 * @private
 */
export const getRequestOrgMessages = async (locale: Locale): Promise<RequestOrganizationMessages> => {
    let usedLocale = locale;

    if (!(isValidLocale(usedLocale))) {
        logger.error(`Invalid locale ${usedLocale}, falling back to ${DEFAULT_LOCALE} for the requestOrg form`, 'Invalid locale');
        usedLocale = DEFAULT_LOCALE;
    }

    const messagesModule:MessagesModule = await getMessagesModuleByLocale(usedLocale);
    const defaultMessages: DefaultMessages | object = messagesModule ? messagesModule.defaultMessages : {};
    const requestOrganizationMessages: RequestOrganizationMessages | object = messagesModule ? messagesModule.requestOrganizationMessages : {};
    const optionsMessages = getOptionsMessages(usedLocale);

    // For non en-US locales, we'll merge en-US messages as a base
    const defaultLocaleMessages = usedLocale !== DEFAULT_LOCALE ? await getRequestOrgMessages(DEFAULT_LOCALE) : {};

    // Flatten these before object.assign so they are dot-props, so nested objects aren't overwritten, deleting properties
    const messages: RequestOrganizationMessages = Object.assign(
        {} as RequestOrganizationMessages,
        defaultLocaleMessages,
        defaultMessages ? flatten(defaultMessages) : {},
        requestOrganizationMessages ? flatten(requestOrganizationMessages) : {},
        optionsMessages ? flatten(optionsMessages) : {},
    );

    return messages;
};

/**
 * @description Retrieves the locale used to display the verification form. It will attempt to bring the best default by looking at these places:
 * - Retrieve from previous user selection in ChangeLocaleComponent
 * - Retrieve based on the country selected
 * - Retrieve from Options
 * - Retrieve from the URL
 * - Retrieve from the VerificationResponse
 * - Retrieve library DEFAULT_LOCALE
 * If found a locale but is not supported by the library, it'll return DEFAULT_LOCALE instead.
 */
export const getLocaleSafely = (viewModel?: ViewModel | RequestOrganizationViewModel, verificationResponse?: VerificationResponse): Locale => {
    function getLocaleFromViewModel(viewModel: ViewModel | RequestOrganizationViewModel): Locale | undefined {
        return getSafe(() => viewModel.localeChoice.value);
    }

    function getLocaleFromCountrySelection(viewModel: ViewModel | RequestOrganizationViewModel): Locale | undefined {
        const country: Country = getSafe(() => viewModel.countryChoice.value);

        return country ? countryToLocale(country) : undefined;
    }

    function getLocaleFromUrl(): Locale | undefined {
        const b = getQueryParamsFromUrl(window.location.href);
        const queryParamLocale = b.get('locale') as Locale;

        return queryParamLocale || undefined;
    }

    function getLocaleFromVerificationResponse(verificationResponse: VerificationResponse) {
        return getSafe(() => verificationResponse.locale);
    }

    const locale: Locale = getLocaleFromViewModel(viewModel)
        || getLocaleFromCountrySelection(viewModel)
        || getOptions().locale
        || getLocaleFromUrl()
        || getLocaleFromVerificationResponse(verificationResponse)
        || DEFAULT_LOCALE;

    try {
        assertValidLocale(locale);

        return locale;
    } catch (error) {
        return DEFAULT_LOCALE;
    }
};

/**
 * @deprecated Use `getMessages()` instead
 */
export const getIntlMessages = (locale: Locale, programTheme: ProgramTheme = null): StringMap => {
    assertValidLocale(locale);

    let optionalMessages: Object = {};
    if (getOptions().messagesWithLocale[locale]) {
        optionalMessages = getOptions().messagesWithLocale[locale];
    } else if (getOptions().messagesWithLocale[DEFAULT_LOCALE]) {
        optionalMessages = getOptions().messagesWithLocale[DEFAULT_LOCALE];
    }

    let sheerIdMessages: Object = {};
    if (programTheme) {
        try {
            sheerIdMessages = programTheme.intl.messages;
        } catch (e) {
            logger.error(e, 'Error accessing programTheme.intl.messages');
        }
    }
    // react-intl doesn't allow the messages object to contain nested objects.
    // Use flatten() so we can access nested objects with dot-strings like 'foo.bar'
    return flatten({ ...sheerIdMessages, ...optionalMessages }) as StringMap;
};

/**
 * @deprecated Use `getLocaleSafely()` instead
 */
export const getLocale = (programTheme: ProgramTheme = null): Locale => {
    let { locale } = getOptions();

    logger.error('getLocale() is deprecated, use getLocaleSafely() instead.');

    if (programTheme) {
        try {
            locale = programTheme.intl.locale as Locale;
        } catch (e) {
            logger.error(e, 'Error accessing programTheme.intl.locale. Did the shape of an object/type change?');
        }
        if (!isValidLocale(locale)) {
            logger.warn(`Invalid locale "${locale}" received from server. Using fallback "${getOptions().locale}" instead.`);
            locale = getOptions().locale;
        }
    }
    return locale || DEFAULT_LOCALE;
};

export const getRequestOrganizationLocale = (programTheme: ProgramTheme = null): Locale => {
    const urlParams = new URLSearchParams(window.location.search);
    let locale: Locale;

    if (urlParams.get('locale')) {
        locale = urlParams.get('locale') as Locale;
    } else {
        locale = getOptions().locale;
    }

    if (programTheme) {
        try {
            locale = programTheme.intl.locale as Locale;
        } catch (e) {
            logger.error(e, 'Error accessing programTheme.intl.locale. Did the shape of an object/type change?');
        }
        if (!isValidLocale(locale)) {
            logger.warn(`Invalid locale "${locale}" received from server. Using fallback "${getOptions().locale}" instead.`);
            locale = getOptions().locale;
        }
    }

    if (!(isValidLocale(locale))) {
        logger.warn(`Invalid locale ${locale}, falling back to en-US`);
        locale = 'en-US';
    }

    return locale;
};

/**
 * @deprecated
 */
export const setLocale = () => {
    logger.error('setLocale() is deprecated, use verificationService.updateLocale() instead.');
};

export const standardizeLocale = (locale: Locale): string => (locale === 'id' ? 'in' : locale.replace('-', '_'));
