/**
 * TODO - preamble
 *
 * Empty objects, useful for initializing
 */

import { deepClone } from '../utils/objects';

import {
    AgePersonalInfoViewModel,
    SeniorPersonalInfoViewModel,
    StudentPersonalInfoViewModel,
    TeacherPersonalInfoViewModel,
    ActiveMilitaryPersonalInfoViewModel,
    DocUploadViewModel,
    FieldValidationErrors,
    InactiveMilitaryPersonalInfoViewModel,
    FirstResponderPersonalInfoViewModel,
    MedicalProfessionalPersonalInfoViewModel,
    EmploymentPersonalInfoViewModel,
    SocialSecurityViewModel,
    ProgramTheme,
    SSOViewModel,
    WithCoreFields,
    MemberPersonalInfoViewModel,
    SMSLoopViewModel,
    RequestOrganizationViewModel,
    VerificationStepMap,
    ViewModel,
    EmailLoopViewModel,
    DriverLicensePersonalInfoViewModel,
    GeneralIdentityPersonalInfoViewModel,
    HybridIdentityPersonalInfoViewModel,
    LicensedProfessionalPersonalInfoViewModel,
    MoverPersonalInfoViewModel,
} from './types';
import { getCustomValidatorFields } from '../validators/customValidators';
import { getLocaleSafely } from '../intl/intl';

const coreFieldsEmpty: WithCoreFields = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
};

const withLocaleAndCountryEmpty = {
    countryChoice: undefined,
    localeChoice: {
        value: undefined,
        label: undefined,
    },
};

const withFieldsToSkipValidationEmpty = {
    fieldsToSkipValidation: undefined,
};

// TODO use Object.freeze here
export const stuPInfoReqEmpty: StudentPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    organization: {
        id: 0,
        name: '',
    },
    metadata: { marketConsentValue: false },
};

// TODO use Object.freeze here
export const seniorPInfoReqEmpty: SeniorPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    postalCode: '',
    metadata: { marketConsentValue: false },
};

export const agePInfoReqEmpty: AgePersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    postalCode: '',
    phoneNumber: '',
    country: 'US',
    metadata: { marketConsentValue: false, carrierConsentValue: false },
};

// TODO use Object.freeze here
export const teaPInfoReqEmpty: TeacherPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    organization: {
        id: 0,
        name: '',
    },
    metadata: { marketConsentValue: false },
};

export const memberInfoReqEmpty: MemberPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    birthDate: '',
    phoneNumber: '',
    memberId: '',
    organization: {
        id: 0,
        name: '',
    },
    metadata: { marketConsentValue: false },
};

export const activeMilitaryPInfoReqEmpty: ActiveMilitaryPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    organization: {
        id: 0,
        name: '',
    },
    status: undefined,
    metadata: { marketConsentValue: false },
};

export const inactiveMilitaryPInfoReqEmpty: InactiveMilitaryPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    dischargeDate: '',
    organization: {
        id: 0,
        name: '',
    },
    status: undefined,
    metadata: { marketConsentValue: false },
};

export const firstResponderPInfoReqEmpty: FirstResponderPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    organization: {
        id: 0,
        name: '',
    },
    status: undefined,
    metadata: { marketConsentValue: false },
};

export const medicalProfessionalPInfoReqEmpty: MedicalProfessionalPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    birthDate: '',
    postalCode: '',
    organization: {
        id: 0,
        name: '',
    },
    status: undefined,
    country: undefined,
    metadata: { marketConsentValue: false },
};


export const employmentPInfoReqEmpty: EmploymentPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    postalCode: '',
    organization: {
        id: 0,
        name: '',
    },
    address1: '',
    city: '',
    state: '',
    metadata: { marketConsentValue: false },
};

export const driverLicensePInfoReqEmpty: DriverLicensePersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withFieldsToSkipValidationEmpty,
    ...withLocaleAndCountryEmpty,
    state: '',
    driverLicenseNumber: '',
    metadata: { marketConsentValue: false },
};

export const generalIdentityPInfoReqEmpty: GeneralIdentityPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withFieldsToSkipValidationEmpty,
    ...withLocaleAndCountryEmpty,
    birthDate: '',
    address1: '',
    city: '',
    state: '',
    postalCode: '',
    metadata: { marketConsentValue: false },
};

export const hybridIdentityPInfoReqEmpty: HybridIdentityPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withFieldsToSkipValidationEmpty,
    ...withLocaleAndCountryEmpty,
    birthDate: '',
    address1: '',
    city: '',
    state: '',
    postalCode: '',
    metadata: { marketConsentValue: false },
    driverLicenseNumber: '',
};

export const licensedProfessionalPInfoReqEmpty: LicensedProfessionalPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withFieldsToSkipValidationEmpty,
    ...withLocaleAndCountryEmpty,
    birthDate: '',
    postalCode: '',
    organization: {
        id: 0,
        name: '',
    },
    statuses: [],
};

export const moverPInfoReqEmpty: MoverPersonalInfoViewModel = {
    ...coreFieldsEmpty,
    ...withFieldsToSkipValidationEmpty,
    ...withLocaleAndCountryEmpty,
    address1: '',
    postalCode: '',
    statuses: [],
    metadata: { marketConsentValue: false },
};

// TODO use Object.freeze here
export const docUploadEmpty: DocUploadViewModel = {
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    file1: undefined,
    file2: undefined,
    file3: undefined,
};

export const ssoEmpty: SSOViewModel = {
    ...withLocaleAndCountryEmpty,
    ...withFieldsToSkipValidationEmpty,
    isSSOActive: false,
};

export const socialSecurityEmpty: SocialSecurityViewModel = {
    ...withLocaleAndCountryEmpty,
    socialSecurityNumber: undefined,
} as const;

export const smsLoopEmpty: SMSLoopViewModel = {
    ...withLocaleAndCountryEmpty,
    smsCode: '',
    phoneNumber: undefined,
};

export const emailLoopEmpty: EmailLoopViewModel = {
    ...withLocaleAndCountryEmpty,
    emailToken: '',
};

export const emptyViewModel = {
    ...withLocaleAndCountryEmpty,
} as ViewModel;

const initialErrorStructure = {
    firstName: undefined,
    lastName: undefined,
    memberId: undefined,
    email: undefined,
    phoneNumber: undefined,
    birthDate: undefined,
    organization: undefined,
    postalCode: undefined,
    address1: undefined,
    city: undefined,
    country: undefined,
    state: undefined,
    dischargeDate: undefined,
    docUpload: undefined,
    status: undefined,
    statuses: undefined,
    socialSecurityNumber: undefined,
    marketConsentValue: undefined,
    carrierConsentValue: undefined,
    smsCode: undefined,
    driverLicenseNumber: undefined,
} as const;

export const getAllEmptyViewModels = (): VerificationStepMap<ViewModel> => ({
    collectStudentPersonalInfo: deepClone(stuPInfoReqEmpty),
    collectTeacherPersonalInfo: deepClone(teaPInfoReqEmpty),
    collectMemberPersonalInfo: deepClone(memberInfoReqEmpty),
    collectMilitaryStatus: deepClone(activeMilitaryPInfoReqEmpty),
    collectActiveMilitaryPersonalInfo: deepClone(activeMilitaryPInfoReqEmpty),
    collectInactiveMilitaryPersonalInfo: deepClone(inactiveMilitaryPInfoReqEmpty),
    collectSeniorPersonalInfo: deepClone(seniorPInfoReqEmpty),
    collectAgePersonalInfo: deepClone(agePInfoReqEmpty),
    collectFirstResponderPersonalInfo: deepClone(firstResponderPInfoReqEmpty),
    collectMedicalProfessionalPersonalInfo: deepClone(medicalProfessionalPInfoReqEmpty),
    collectEmployeePersonalInfo: deepClone(employmentPInfoReqEmpty),
    collectSocialSecurityNumber: deepClone(socialSecurityEmpty),
    collectDriverLicensePersonalInfo: deepClone(driverLicensePInfoReqEmpty),
    collectGeneralIdentityPersonalInfo: deepClone(generalIdentityPInfoReqEmpty),
    collectHybridIdentityPersonalInfo: deepClone(hybridIdentityPInfoReqEmpty),
    collectLicensedProfessionalPersonalInfo: deepClone(licensedProfessionalPInfoReqEmpty),
    collectMoverPersonalInfo: deepClone(moverPInfoReqEmpty),
    docUpload: deepClone(docUploadEmpty),
    cancelEmailLoop: deepClone(emailLoopEmpty),
    sso: deepClone(ssoEmpty),
    smsLoop: deepClone(smsLoopEmpty),
    emailLoop: deepClone(emailLoopEmpty),
    pending: deepClone(emptyViewModel),
    success: deepClone(emptyViewModel),
    error: deepClone(emptyViewModel),
    cancelSocialSecurityNumber: deepClone(emptyViewModel),
    docReviewLimitExceeded: deepClone(emptyViewModel),
});

export const getFieldValidationErrorsEmpty = (): FieldValidationErrors => deepClone(initialErrorStructure);

export const getExtendedFieldValidationErrorsEmpty = () => {
    const customValidatorFieldsEmpty = {};
    const customValidatorFields = getCustomValidatorFields();
    if (customValidatorFields) {
        for (const field of customValidatorFields) {
            customValidatorFieldsEmpty[field] = undefined;
        }
    }
    return customValidatorFieldsEmpty;
};

export const GetEmptyTheme = (): ProgramTheme => ({
    intl: {
        locale: getLocaleSafely(),
        // @ts-ignore
        messages: {},
    },
    customCss: '',
});

export const requestOrganizationEmpty: RequestOrganizationViewModel = {
    ...withLocaleAndCountryEmpty,
    orgDomain: '',
    orgName: '',
    firstName: '',
    lastName: '',
    email: '',
    displayResults: false,
    searchByOrgName: false,
    completeRequest: false,
    eligibleOrgs: [],
    ineligibleOrgs: [],
    orgCountryError: null,
    orgDomainError: null,
    orgNameError: null,
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    isSearching: false,
};
