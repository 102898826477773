import pluralizationsAndDateLocaleData from 'react-intl/locale-data/en.js';
import { MessagesModule } from '../../types/types';

import { defaultMessages } from './defaultMessages';
import { firstResponderMessages } from './firstResponderMessages';
import { militaryMessages } from './militaryMessages';
import { seniorMessages } from './seniorMessages';
import { ageMessages } from './ageMessages';
import { studentMessages } from './studentMessages';
import { teacherMessages } from './teacherMessages';
import { memberMessages } from './memberMessages';
import { medicalMessages } from './medicalMessages';
import { employmentMessages } from './employmentMessages';
import { requestOrganizationMessages } from './requestOrganizationMessages';
import { identityMessages } from './identityMessages';
import { licensedProfessionalMessages } from './licensedProfessionalMessages';
import { moverMessages } from './moverMessages';

export const messages: MessagesModule = {
    defaultMessages,
    pluralizationsAndDateLocaleData,
    requestOrganizationMessages,
    segmentMessages: {
        firstResponder: firstResponderMessages,
        military: militaryMessages,
        senior: seniorMessages,
        age: ageMessages,
        student: studentMessages,
        teacher: teacherMessages,
        member: memberMessages,
        medical: medicalMessages,
        employment: employmentMessages,
        identity: identityMessages,
        licensedProfessional: licensedProfessionalMessages,
        recentMover: moverMessages,
    },
};
