import React from 'react';
import { Provider, Store } from 'react-redux';

import { FormWrapperComponent as FormWrapper } from '../FormWrapper/FormWrapperComponent';
import { NewVerificationComponent } from '../NewVerificationComponent';

import { DatabaseId, ReduxState, ProgramIdAction } from '../../lib/types/types';
import { getStore } from '../../lib/VerificationService/VerificationServiceStore';
import { setDimension } from '../../lib/GoogleAnalytics/ga';
import { getSafe } from '../../lib/utils/objects';
import { addPredefinedHooks } from '../../lib/hooks/hooks';
import { getPublishedVersion } from '../../lib/ServerApi/ApiUrls';

interface VerificationFormProps {
    programId: DatabaseId;
    // TODO fix next line
    trackingId?: string; // eslint-disable-line
}

const store: Store<ReduxState> = getStore();

// This call can take place at any time after the jslib .js file is parsed
// It only needs to be called once per page-load
setDimension('jslibVersionActual', getSafe(() => getPublishedVersion(), '?'));

export const VerificationForm = React.forwardRef(({ programId, trackingId = undefined }: VerificationFormProps, ref: any) => {
    addPredefinedHooks(); // keep this as early as possible in the jslib initialization

    store.dispatch<ProgramIdAction>({ programId, type: 'PROGRAM_ID' });

    if (ref) {
        ref.current = { // eslint-disable-line
            refresh: React.useCallback(() => store.dispatch({ type: 'FORCE_UPDATE' }), []),
            reset: React.useCallback(() => store.dispatch({ type: 'RESET_STATE' }), []),
        };
    }

    return (
        <Provider store={store}>
            <FormWrapper>
                <NewVerificationComponent programId={programId} trackingId={trackingId} />
            </FormWrapper>
        </Provider>
    );
});
