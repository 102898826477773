import React, { RefObject, useState, useEffect } from 'react';
import { useCombobox } from 'downshift';

import { FetchOrganizationsComponent } from '../../FetchOrganizationsComponent';
import {
    Organization, DatabaseId, Country, Locale,
} from '../../../lib/types/types';
import { setRef } from '../../../lib/refs/refs';
import { assertValidFunction } from '../../../lib/types/assertions';
import { handleStateChange } from './TypeaheadComponentHelper';

interface TypeaheadProps {
    onChange: any;
    programId: DatabaseId;
    inputHtmlId: string;
    autoFocus?: boolean;
    className?: string;
    countryCode?: Country;
    disabled?: boolean;
    minimumSearchValueLength?: number;
    placeholder?: string;
    value?: Organization;
    openOrgSearchEnabled?: boolean;
    orgSearchUrl?: string;
    orgSearchTags?: string;
    isRequired?: boolean;
    locale?: Locale;
    urlAddSchoolForm?: string;
}

const Typeahead = ({
    onChange,
    programId,
    className,
    countryCode,
    disabled,
    minimumSearchValueLength,
    placeholder,
    value,
    inputHtmlId,
    openOrgSearchEnabled,
    orgSearchUrl,
    orgSearchTags,
    isRequired,
    locale,
    urlAddSchoolForm,
}: TypeaheadProps) => {
    const [inputValue, setInputValue] = useState('');
    const [loadedOrgs, setLoadedOrgs] = useState([]);

    assertValidFunction(onChange);

    const itemToString = (item: Organization) => (item ? item.name : '');

    const {
        isOpen,
        highlightedIndex,
        getMenuProps,
        getItemProps,
        getInputProps,
        getComboboxProps,
    } = useCombobox({
        itemToString,
        id: inputHtmlId,
        items: loadedOrgs,
        onStateChange: changes => handleStateChange(changes, onChange, openOrgSearchEnabled),
        onInputValueChange: ({ inputValue }) => {
            setInputValue(inputValue);
        },
        selectedItem: value,
        onSelectedItemChange: ({ selectedItem }) => {
            onChange(selectedItem);
        },
    });

    // Maintain same labelledby value as previous versions even though label has "for" attribute
    const comboboxArgs = { 'aria-labelledby': `${inputHtmlId}-label` };

    const inputRef: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    useEffect(() => {
        setRef('organization', inputRef.current);
    }, []);

    return (
        <>
            <div className="sid-organization-list" {...getComboboxProps(comboboxArgs)}>
                <input
                    {...getInputProps({ ref: inputRef })}
                    className={`sid-l-full-width sid-hidden-placeholder sid-text-input ${className}`}
                    disabled={disabled}
                    id={inputHtmlId}
                    placeholder={placeholder}
                    aria-required={isRequired}
                />

                {/* The menu (div) is always mounted. Its child is conditional. Fixes "Downshift error: getMenuProps() was not applied correctly" */}
                <div {...getMenuProps()}>
                    {isOpen
                        ? (
                            <FetchOrganizationsComponent
                                countryCode={countryCode}
                                getItemProps={getItemProps}
                                highlightedIndex={highlightedIndex}
                                itemToString={itemToString}
                                minimumSearchValueLength={minimumSearchValueLength}
                                onLoaded={loadedOrganizations => setLoadedOrgs(loadedOrganizations)}
                                programId={programId}
                                searchValue={inputValue}
                                orgSearchUrl={orgSearchUrl}
                                orgSearchTags={orgSearchTags}
                                locale={locale}
                                urlAddSchoolForm={urlAddSchoolForm}
                                openOrgSearchEnabled={openOrgSearchEnabled}
                            />
                        )
                        : null}
                </div>
            </div>
        </>
    );
};

export const TypeaheadComponent = Typeahead;
