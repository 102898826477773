import { produce } from 'immer';
import { InjectedIntl } from 'react-intl';
import {
    FieldId,
    FieldValidationErrors,
    Organization,
    VerificationService,
    FieldContent,
    FieldRef,
    ExtendedFieldId,
    WithOrganization,
    InputSelectOnKeyDown,
    FormSelectChoice,
    Country,
    ProgramTheme,
    Locale,
    ViewModel,
    MilitaryStatusResponse,
    State,
    PendingResponse,
    DocUploadResponse,
} from '../../types/types';
import { validateFieldById, getFieldValidationErrors, isFormErrored } from '../../validators/validators';
import { logger } from '../logger/logger';
import { setFocus } from '../browser/inputHelpers';
import { getRefByFieldId, getRefs } from '../../refs/refs';
import { VerificationStepsEnum, MilitaryStatusDefaultMessagesEnum } from '../../types/runtimeTypes';
import { getSafe } from '../objects';
import {
    getConfiguredCountries, getAvailableLocales, getConfiguredStates, getEstimatedReviewTime, getMaxReviewTime,
} from '../../ProgramTheme/programThemeGetters';
import { getQueryParamsFromUrl } from '../routing/Url';

export const updateViewModelOrganization = (
    organization: Organization,
    verificationService: VerificationService,
) => {
    const viewModel = verificationService.viewModel as WithOrganization;
    const nextState = produce(viewModel, (draft) => {
        draft.organization.id = organization.id;
        draft.organization.idExtended = organization.idExtended;
        draft.organization.name = organization.name;
        if (viewModel.organization && viewModel.organization.source !== undefined) {
            draft.organization.source = viewModel.organization.source;
        }
    });
    verificationService.updateViewModel(nextState as ViewModel);
};

export const populateViewModelFromQueryParams = (verificationService: VerificationService) => {
    const viewModel = verificationService.viewModel as WithOrganization;
    const queryParams = getQueryParamsFromUrl();

    const nextState = produce(viewModel, (draft) => {
        Object.keys(viewModel).forEach((key) => {
            if (queryParams.has(key)) {
                draft[key] = queryParams.get(key);
            }
        });
    });

    verificationService.updateViewModel(nextState as ViewModel);
};

export const updateFieldValidationErrorsByFieldId = (
    fieldId: FieldId | ExtendedFieldId,
    value: FieldContent,
    verificationService: VerificationService,
) => {
    const { fieldValidationErrors } = verificationService;
    const nextState = produce(fieldValidationErrors, (draft) => {
        if (draft[fieldId]) {
            draft[fieldId] = validateFieldById(fieldId, value, verificationService.formValidationOptions);
        }
    });
    updateFieldValidationErrors(nextState, verificationService);
};

export const updateFieldValidationErrors = (fieldValidationErrors: FieldValidationErrors, verificationService: VerificationService) => {
    verificationService.updateFieldValidationErrors(fieldValidationErrors);
};

export const shouldCollectAddressFields = (countryChoice: FormSelectChoice<Country, string>, countries: Country[]): boolean => (getSafe(() => countries.length === 1)
        && getSafe(() => countries[0]) === 'US')
        || getSafe(() => countryChoice.value === 'US')
        || getSafe(() => countryChoice.value === undefined);

export const getAvailableMilitaryStatuses = (verificationService: VerificationService, intl: InjectedIntl): FormSelectChoice[] => {
    const availableStatusesResponse = (verificationService.verificationResponse as MilitaryStatusResponse).availableStatuses
        || (verificationService.previousVerificationResponse
            && (verificationService.previousVerificationResponse as MilitaryStatusResponse).availableStatuses);
    const availableStatuses: FormSelectChoice[] = [];

    if (!availableStatusesResponse) {
        return null;
    }

    for (const status of availableStatusesResponse) {
        availableStatuses.push({
            value: status,
            label: intl.formatMessage({ id: status, defaultMessage: MilitaryStatusDefaultMessagesEnum[status] }),
        });
    }
    return availableStatuses;
};

/**
 * @private
 */
export const getFieldDisplayOrderFromRefs = (): FieldId[] | ExtendedFieldId[] => {
    const refs: FieldRef[] = getRefs();
    const fieldDisplayOrder: FieldId | ExtendedFieldId[] = [];

    refs.map((refObject: FieldRef) => fieldDisplayOrder.push(refObject.fieldId));

    return fieldDisplayOrder;
};

/**
 * @private
 */
export const getFirstErroredFieldId = (
    fieldDisplayOrder: FieldId[] | ExtendedFieldId[],
    fieldValidationErrors: FieldValidationErrors,
): FieldId | ExtendedFieldId => {
    let firstErrordFieldId: FieldId | ExtendedFieldId;

    for (const field of fieldDisplayOrder) {
        if (fieldValidationErrors[field]) {
            firstErrordFieldId = field;
            return adjustFirstErroredFieldId(firstErrordFieldId);
        }
    }
};

/**
 * HD-638 - focus on year, rather than month (which is an open <select> that covers error msg)
 */
export const adjustFirstErroredFieldId = (firstErroredFieldId: FieldId | ExtendedFieldId): FieldId | ExtendedFieldId => {
    if (firstErroredFieldId === 'birthDate' || firstErroredFieldId === 'birthDateDay') {
        return 'birthDateYear';
    }
    return firstErroredFieldId;
};

/**
 * @private
 */
export const handleEmailOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const submitButtonRef: HTMLElement = getRefByFieldId('submitButton');
    if (event.key === 'Enter' && submitButtonRef) {
        submitButtonRef.click();
    }
};

/**
 * @private
 */
export const handleCountryOnKeyDown: InputSelectOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' && !event.shiftKey) {
        event.preventDefault();
        setFocus('organization');
    }
};

/**
 * @public
 */
export const submitForm = (viewModel: ViewModel, verificationService: VerificationService, step: VerificationStepsEnum) => {
    const currentFieldValidationErrors = getFieldValidationErrors(viewModel, verificationService.formValidationOptions);
    const isFormValid = !isFormErrored(currentFieldValidationErrors);

    if (isFormValid) {
        logger.info(`${step} submitting form`);
        verificationService.submitStep(step, viewModel, verificationService.verificationResponse);
    } else {
        const fieldDisplayOrder: FieldId[] | ExtendedFieldId[] = getFieldDisplayOrderFromRefs();
        const firstErroredFieldId: FieldId | ExtendedFieldId = getFirstErroredFieldId(fieldDisplayOrder, currentFieldValidationErrors);
        updateFieldValidationErrors(currentFieldValidationErrors, verificationService);
        setFocus(firstErroredFieldId);
    }
};

/**
 * @public
 * @description Help with commonly-accessed values. Returns the fully-resolved verbiage in the appropriate language.
 * @return {Object}
 */
export const getEstAndMaxReviewTimes = (verificationResponse: DocUploadResponse | PendingResponse, programTheme: ProgramTheme, intl: any)
: { estReviewTime: string; maxReviewTime: string} => {
    const estReviewTime = intl.formatMessage({
        id: `dateTime.${getSafe(() => verificationResponse.estimatedReviewTime) || getEstimatedReviewTime(programTheme)}`,
        default: 'a few minutes',
    });
    const maxReviewTime = intl.formatMessage({
        id: `dateTime.${getSafe(() => verificationResponse.maxReviewTime) || getMaxReviewTime(programTheme)}`,
        default: '2 hours',
    });
    return {
        estReviewTime,
        maxReviewTime,
    };
};

/**
 * @private
 */
export const getAvailableCountryChoices = (programTheme: ProgramTheme, intl: InjectedIntl): FormSelectChoice<Country, string>[] => {
    const availableCountries: Country[] = getConfiguredCountries(programTheme);
    return availableCountries
        .map(countryCode => ({
            value: countryCode,
            label: intl.formatMessage({
                id: `countries.${countryCode}`,
                defaultMessage: countryCode,
            }),
        }));
};

export const getAvailableStateChoices = (programTheme: ProgramTheme, intl: InjectedIntl): FormSelectChoice<State, string>[] => {
    const availableStates: State[] = getConfiguredStates(programTheme);
    return availableStates
        .map(stateCode => ({
            value: stateCode,
            label: intl.formatMessage({
                id: `states.${stateCode}`,
                defaultMessage: stateCode,
            }),
        }));
};

/**
 * @private
 */
export const getAvailableLocaleChoices = (programTheme: ProgramTheme, intl: InjectedIntl): FormSelectChoice<Locale, string>[] => {
    const availableLocales: Locale[] = getAvailableLocales(programTheme);
    return availableLocales
        .map(locale => ({
            value: locale,
            label: intl.formatMessage({
                id: `locales.${locale}`,
                defaultMessage: locale,
            }),
        }));
};

/**
 * @private
 */
export const getDefaultCountryChoice = (countryChoices: FormSelectChoice<Country, string>[]): FormSelectChoice<Country, string> => {
    if (countryChoices.length > 0) {
        return countryChoices[0];
    }
    return { value: 'US', label: 'United States' };
};

/**
 * @private
 */
export const produceDraftViewModel = <T extends ViewModel>(previousModel: T, key: keyof T, value: any) => produce(previousModel, (draft: T) => {
    (draft[key] as any) = value;
});
