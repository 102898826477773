import React from 'react';
import produce from 'immer';
import {
    injectIntl, FormattedMessage, FormattedHTMLMessage, InjectedIntl,
} from 'react-intl';

import { logger } from '../../lib/utils/logger/logger';
import {
    VerificationService, StudentPersonalInfoViewModel, EmailLoopViewModel, EmailLoopResponse,
} from '../../lib/types/types';
import { VerificationStepsEnum } from '../../lib/types/runtimeTypes';
import { getSafe } from '../../lib/utils/objects';
import { getLogoUrl } from '../../lib/ProgramTheme/programThemeGetters';

import { LogoComponent } from '../LogoComponent/LogoComponent';
import { populateViewModelFromQueryParams } from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';
import { usePollingInterval } from '../../lib/utils/usePollingInterval/usePollingInterval';

import { VerificationApiClient } from '../../lib/ServerApi/VerificationApiClient';

const EMAIL_LOOP_POLL_DURATION = 10000;

interface StepEmailLoopComponentProps {
    intl: InjectedIntl;
    verificationService: VerificationService;
}

export const StepEmailLoop = ({ intl, verificationService }: StepEmailLoopComponentProps) => {
    const verificationResponse = verificationService.verificationResponse as EmailLoopResponse;
    const { programId } = verificationService;
    const { verificationId } = verificationResponse;
    const { canResendEmailLoop } = verificationResponse;
    const canCancelEmailLoop = verificationResponse.cancelUrl;
    const viewModel = verificationService.viewModel as EmailLoopViewModel;
    const previousViewModel = (verificationService.previousViewModel as StudentPersonalInfoViewModel) || undefined;
    const previousVerificationResponse = verificationService.previousVerificationResponse || undefined;

    const [resendMessage, setResendMessage] = React.useState('');

    const hasLogo = !!getLogoUrl(verificationService.programTheme);
    const logo = hasLogo ? (
        <LogoComponent verificationService={verificationService} />
    ) : (
        <img
            className="sid-l-lead-image"
            alt="design element"
            src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/doc-pending.svg"
        />
    );
    const email = getSafe(() => previousViewModel.email);

    const submitForm = () => {
        logger.info('StepEmailLoopComponent submitting form');
        verificationService.submitStep(VerificationStepsEnum.emailLoop, viewModel, verificationService.verificationResponse);
    };

    React.useEffect(() => {
        populateViewModelFromQueryParams(verificationService);
    }, []);

    React.useEffect(() => {
        if (viewModel.emailToken) {
            submitForm();
        }
    }, [viewModel.emailToken]);

    usePollingInterval(() => {
        verificationService.fetchExistingVerificationRequest(programId, verificationId, previousVerificationResponse, previousViewModel, false);
    }, EMAIL_LOOP_POLL_DURATION);

    const resendEmail = async (verificationId) => {
        const response = await VerificationApiClient.getResendNewEmailCode(verificationId);
        if (response) {
            const successMsgObject = { id: 'step.emailLoop.successResend', defaultMessage: 'Email re-sent successfully.' };
            const maxRetriesMsgObject = {
                id: 'step.emailLoop.errors.resend',
                defaultMessage: 'Error sending email',
            };
            const message = response.status === 204 ? intl.formatMessage(successMsgObject) : intl.formatMessage(maxRetriesMsgObject);
            setResendMessage(message);
        } else {
            setResendMessage(intl.formatMessage({ id: 'step.emailLoop.errors.resend', defaultMessage: 'Error sending email' }));
        }
    };

    const cancelEmailLoop = async () => {
        logger.info('StepEmailLoopComponent submitting form after skipping email loop');
        const nextState = produce(viewModel, () => {});
        verificationService.submitStep(VerificationStepsEnum.cancelEmailLoop, nextState, verificationService.verificationResponse);
    };

    return (
        <div id="sid-step-email-loop" className="sid-l-container">
            <div className="sid-header sid-l-horz-center">
                <div className="sid-l-horz-center">
                    {logo}
                </div>
                <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
                    <FormattedMessage id="step.emailLoop.title" defaultMessage="Check your email" />
                </div>
                <div className="sid-header__subtitle-one">
                    { email ? (
                        <FormattedMessage
                            id="step.emailLoop.subtitleWithEmail"
                            defaultMessage="An email has been sent to {email} with a personalized link to complete the verification process."
                            values={{ email }}
                        />
                    ) : (
                        <FormattedMessage
                            id="step.emailLoop.subtitleWithoutEmail"
                            defaultMessage="An email has been sent to your email account with a personalized link to complete the verification process."
                        />
                    )}
                </div>
                <div className="sid-l-space-top-md" />
                <div className="sid-header__subtitle-two sid-h-small-text sid-l-space-top-md">
                    <FormattedMessage
                        id="step.emailLoop.subtitle2"
                        defaultMessage="Please check for an email from us (verify@sheerid.com) for all the details."
                    />
                </div>
                { canCancelEmailLoop && (
                    <div className="sid-skip-email sid-l-space-top-lg">
                        <FormattedMessage id="step.emailLoop.skipEmail" defaultMessage="Unable to retrieve this email? Click " />
                        <button type="button" onClick={() => cancelEmailLoop()} className="sid-incorrect-number__refresh-link">
                            <FormattedHTMLMessage id="step.emailLoop.skipEmailCTA" defaultMessage="here" />
                        </button>
                        <FormattedMessage id="step.emailLoop.skipEmail2" defaultMessage=" to proceed." />
                    </div>
                )}
                { canResendEmailLoop && (
                    <div className="sid-header__resend-btn sid-l-space-top-md">
                        <button
                            id="sid-resend-email"
                            onClick={() => resendEmail(verificationId)}
                            type="submit"
                            className="sid-btn sid-btn--light sid-submit__cancel sid-l-horz-center"
                            aria-label="re-send"
                        >
                            <FormattedMessage id="step.emailLoop.resendButton" defaultMessage="Re-send" />
                        </button>
                    </div>
                )}
                {
                    resendMessage && (
                        <div>
                            <p>
                                {resendMessage}
                            </p>
                        </div>
                    )
                }
                <div className="sid-l-space-top-xl" />
            </div>
        </div>
    );
};

export const StepEmailLoopComponent = injectIntl(StepEmailLoop);
