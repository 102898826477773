import { Segment } from '../../lib/types/types';
import { studentMessages } from '../../lib/intl/messages/studentMessages';
import { teacherMessages } from '../../lib/intl/messages/teacherMessages';
import { memberMessages } from '../../lib/intl/messages/memberMessages';
import { militaryMessages } from '../../lib/intl/messages/militaryMessages';
import { seniorMessages } from '../../lib/intl/messages/seniorMessages';
import { firstResponderMessages } from '../../lib/intl/messages/firstResponderMessages';
import { medicalMessages } from '../../lib/intl/messages/medicalMessages';
import { ageMessages } from '../../lib/intl/messages/ageMessages';
import { employmentMessages } from '../../lib/intl/messages/employmentMessages';
import { identityMessages } from '../../lib/intl/messages/identityMessages';
import { licensedProfessionalMessages } from '../../lib/intl/messages/licensedProfessionalMessages';
import { moverMessages } from '../../lib/intl/messages/moverMessages';


type Messages = {
    default: string;
    docUpload: string;
};

type HowDoesVerifyingWorkMessages = Record<Segment, Messages>;

export function howDoesVerifyingWorkMessages(): HowDoesVerifyingWorkMessages {
    return {
        student: {
            default: studentMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: studentMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        teacher: {
            default: teacherMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: teacherMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        member: {
            default: memberMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: memberMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        military: {
            default: militaryMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: militaryMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        senior: {
            default: seniorMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: seniorMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        firstResponder: {
            default: firstResponderMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: firstResponderMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        medical: {
            default: medicalMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: medicalMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        age: {
            default: ageMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: ageMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        employment: {
            default: employmentMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: employmentMessages.step.personalInfo.howDoesVerifyingWorkDetails,
        },
        identity: {
            default: identityMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: identityMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        licensedProfessional: {
            default: licensedProfessionalMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: licensedProfessionalMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
        recentMover: {
            default: moverMessages.step.personalInfo.howDoesVerifyingWorkDetails,
            docUpload: moverMessages.step.docUpload.howDoesVerifyingWorkDetails,
        },
    };
}
