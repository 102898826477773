/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from '../../types/types';

export const licensedProfessionalMessages: SegmentSpecificMessages = {
    emailExplanation: 'Needed to send you your unique code',
    step: {
        personalInfo: {
            title: 'Unlock this Special Offer',
            subtitle: '"Verify you have a professional license in your field.',
            howDoesVerifyingWorkDetails: `
            To be eligible for this offer, you must hold a current professional license in your specified field. Eligible fields are shown in the Status selector of this form.
            <br><br>
            {companyName} uses SheerID, a trusted partner, to verify that you are a licensed professional.
            SheerID only collects information that assists in verifying your eligibility. Additional information or documentation may be requested.`,
            tryAgain: {
                title: 'Check Your Name & other fields',
                subtitle: 'Your information must match what is on your government-issued document(s).',
            },
        },
        docUpload: {
            title: 'We need your professional license or certificate',
            subtitle: 'Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.',
            howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying licensed professionals.
            <br><br>
            Documents are used only for verifying your licensure or certification status is current and will be permanently deleted after review is finalized. They will not be shared with {companyName}`,
            uploadInstructions: 'Upload a government-issued document that shows',
        },
        success: {
            title: "You've been verified",
            subtitle: 'Here is your personal coupon code. It is unique to you and can only be used once.',
            redirectButtonText: 'Use code now',
        },
    },
};
