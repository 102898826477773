import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FieldIdEnum } from '../../../lib/types/runtimeTypes';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';
import { displaySSN, unDisplaySSN } from './SSNHelper';

export const SocialSecurity = ({
    value,
    isErrored,
    onChange,
    intl,
    placeholder = '',
}: FormFieldComponentProps<string>) => {
    const changeSsn = (value: string) => {
        onChange(value);
    };

    return (
        <div className="sid-field sid-ssn">
            <div className="sid-l-space-top-md" />

            <label htmlFor="sid-ssn">
                <div className={`sid-field__label sid-field__label--required sid-l-space-btm-sm ${placeholder ? 'sid-h-screen-reader-only' : ''}`}>
                    <FormattedHTMLMessage
                        id="ssn"
                        defaultMessage="Social Security Number"
                    />
                </div>
            </label>

            <InputTextComponent
                id="ssn"
                refId={FieldIdEnum.socialSecurityNumber}
                className="sid-ssn__input"
                isErrored={isErrored}
                required
                placeholder={placeholder || intl.formatMessage({ id: 'ssnPlaceholder', defaultMessage: '000-00-0000' })}
                value={displaySSN(value)}
                hidePlaceholder={false}
                onChange={e => changeSsn(unDisplaySSN(e.target.value))}
            />

            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidSocialSecurityNumber"
                            defaultMessage="Invalid SSN"
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

export const SocialSecurityNumber = injectIntl(SocialSecurity);
