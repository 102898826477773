import React from 'react';
import { FormattedHTMLMessage, injectIntl, InjectedIntl } from 'react-intl';
import { VerificationService } from '../../lib/types/types';
import { getPrivacyPolicyUrl, getPrivacyPolicyCompanyName } from '../../lib/ProgramTheme/programThemeGetters';
import { LinkExternal } from '../LinkExternal';

interface PrivacyPolicyLinkProps {
    verificationService: VerificationService;
    includeCompanyName: boolean;
    intl?: InjectedIntl; // eslint-disable-line
}

const PrivacyPolicyLink = ({ verificationService, includeCompanyName, intl }: PrivacyPolicyLinkProps) => {
    const { programTheme } = verificationService;
    const privacyPolicyHref = getPrivacyPolicyUrl(programTheme);
    const companyName = `${getPrivacyPolicyCompanyName(programTheme, intl)} `;

    return (
        <LinkExternal className="sid-privacy-policy sid-link" href={privacyPolicyHref}>
            {includeCompanyName
                ? <span>{companyName}</span>
                : null}
            <FormattedHTMLMessage id="privacyPolicy" defaultMessage="Privacy Policy" />
        </LinkExternal>
    );
};

export const PrivacyPolicyLinkComponent = injectIntl(PrivacyPolicyLink);
