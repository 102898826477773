/**
 * assertions.ts
 *
 * Useful for runtime checking. Either pass a valid arg, or bail out by throwing an Error.
 */
import {
    MockStep, VerificationStep, Segment, DatabaseId, Locale, FieldId, HookName, Hook, ConversionRequest,
} from './types';
import {
    MockSteps, VerificationSteps, Segments, FieldIds, TryAgainSteps, HookNames, Locales,
} from './runtimeTypes';
import { getCustomValidatorFields } from '../validators/customValidators';

export const assertValidVerificationStepName = (candidate: VerificationStep) => {
    // TODO - only in dev mode
    if (VerificationSteps.indexOf(candidate) < 0) {
        throw new Error(`Expected valid verification step name but received "${candidate}".`);
    }
};

export const assertValidMockStepName = (candidate: MockStep) => {
    // TODO - only in dev mode
    if (MockSteps.indexOf(candidate) < 0) {
        throw new Error(`Expected valid verification step name but received "${candidate}".`);
    }
};

export const assertValidSegmentName = (candidate: Segment) => {
    // TODO - only in dev mode
    if (Segments.indexOf(candidate) < 0) {
        throw new Error(`Expected valid segment name but received "${candidate}".`);
    }
};

export const assertValidLocale = (locale: Locale) => {
    if (!(isValidLocale(locale))) {
        throw new Error(`Invalid locale ${locale}, expected one of ${Locales.join(', ')}`);
    }
};

export const isValidLocale = (locale: Locale) => Locales.indexOf(locale) > -1;

export const assertValidHtmlElement = (element: HTMLElement) => {
    if (!(element && element.nodeType === Node.ELEMENT_NODE)) {
        throw new Error(`Expected argument of type Node.ELEMENT_NODE but received "${typeof element} ${element.nodeType}"`);
    }
};

export const assertValidProgramId = (programId: DatabaseId) => {
    assertValidDatabaseId(programId);
};

export const assertValidFieldId = (candidate: FieldId | string) => {
    const customValidatorFields = getCustomValidatorFields();
    if (FieldIds.indexOf(candidate) < 0 && customValidatorFields.indexOf(candidate) < 0) {
        throw new Error(`Expected valid field ID but received ${candidate}.
            Valid FieldIds are [${FieldIds.join(', ')}, ${customValidatorFields.join(', ')}]`);
    }
};

export const assertValidTryAgainStep = (candidate: VerificationStep) => {
    if (TryAgainSteps.indexOf(candidate) < 0) {
        throw new Error(`Expected valid try again step but received ${candidate}. Valid TryAgainSteps are [${TryAgainSteps.join(', ')}]`);
    }
};

export const assertValidHook = (hook: Hook) => {
    assertValidHookName(hook.name);
    assertValidFunction(hook.callback);
};

export const assertValidHookName = (candidate: HookName) => {
    if (HookNames.indexOf(candidate) < 0) {
        throw new Error(`Expected valid hook name but received ${candidate}. Valid HookNames are [${HookNames.join(', ')}]`);
    }
};

export const assertValidFunction = (candidate: Function) => {
    if (typeof candidate !== 'function') {
        throw new Error(`Expected type "function", but received ${typeof candidate}`);
    }
};

export const assertValidTrackingId = (candidate: string) => {
    if (typeof candidate !== 'string') {
        throw new Error(`Expected trackingId to be a string, but received ${typeof candidate} instead.`);
    }
    if (candidate.length < 1) {
        throw new Error('Expected trackingId string length greather than 0.');
    }
};

export const assertValidConversionRequest = (candidate: ConversionRequest) => {
    if (typeof candidate !== 'object') {
        throw new Error(`Expected conversion request to be an object, but received ${typeof candidate} instead.`);
    }
    if (candidate.hasOwnProperty('amount') && typeof candidate.amount !== 'number') {
        throw new Error('Expected conversion request property "amount" to have type number.');
    }
};

export const assertValidDatabaseId = (candidate: string) => {
    const validId = new RegExp('^[0-9a-fA-F]{24}$');
    if (!validId.test(candidate)) {
        throw new Error(`Invalid databaseId "${candidate}". Expected a 24-digit hexadecimal string.`);
    }
};
