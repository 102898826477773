import React from 'react';
import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { logger } from '../../../lib/utils/logger/logger';
import { FormFieldComponentProps, FormSelectChoice } from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';
import { setFocus } from '../../../lib/utils/browser/inputHelpers';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';
import { formatTwoDigitValues } from '../../../lib/utils/datesFormat/formatTwoDigitValues';

export const DischargeDate = ({
    onChange,
    intl,
    isErrored,
    value,
}: FormFieldComponentProps & InjectedIntlProps) => {
    const dischargeDateParts = value.split('-');
    const dischargeDateYear = Number.parseInt(dischargeDateParts[0], 10) || undefined;
    const dischargeDateMonth = Number.parseInt(dischargeDateParts[1], 10) || undefined;
    const dischargeDateDay = 1;

    const minDischargeYear = 1900;
    const thisYear = new Date().getFullYear();

    const months: FormSelectChoice[] = [
        { value: '1', label: intl.formatMessage({ id: 'dateTime.january', defaultMessage: 'January' }) },
        { value: '2', label: intl.formatMessage({ id: 'dateTime.february', defaultMessage: 'February' }) },
        { value: '3', label: intl.formatMessage({ id: 'dateTime.march', defaultMessage: 'March' }) },
        { value: '4', label: intl.formatMessage({ id: 'dateTime.april', defaultMessage: 'April' }) },
        { value: '5', label: intl.formatMessage({ id: 'dateTime.may', defaultMessage: 'May' }) },
        { value: '6', label: intl.formatMessage({ id: 'dateTime.june', defaultMessage: 'June' }) },
        { value: '7', label: intl.formatMessage({ id: 'dateTime.july', defaultMessage: 'July' }) },
        { value: '8', label: intl.formatMessage({ id: 'dateTime.august', defaultMessage: 'August' }) },
        { value: '9', label: intl.formatMessage({ id: 'dateTime.september', defaultMessage: 'September' }) },
        { value: '10', label: intl.formatMessage({ id: 'dateTime.october', defaultMessage: 'October' }) },
        { value: '11', label: intl.formatMessage({ id: 'dateTime.november', defaultMessage: 'November' }) },
        { value: '12', label: intl.formatMessage({ id: 'dateTime.december', defaultMessage: 'December' }) },
    ];

    const updateDischargeMonth = (month) => {
        if (month === '' || month === null) {
            onChange(`${dischargeDateYear}--${dischargeDateDay}`);
        }

        const newVal = Number.parseInt(month, 10);
        if (typeof newVal === 'number' && newVal >= 1 && newVal <= 12) {
            onChange(`${dischargeDateYear}-${formatTwoDigitValues(month)}-${formatTwoDigitValues(dischargeDateDay)}`);
        } else {
            logger.warn(`Value '${newVal}' is an invalid discharge month`);
        }
    };

    const updateDischargeYear = (year) => {
        if (year === '') {
            onChange(`-${dischargeDateMonth}-${dischargeDateDay}`);
        }
        const newVal = Number.parseInt(year, 10);
        if (typeof newVal === 'number' && newVal >= 1 && newVal <= thisYear) {
            onChange(`${newVal}-${formatTwoDigitValues(dischargeDateMonth)}-${formatTwoDigitValues(dischargeDateDay)}`);
        } else {
            logger.warn(`Value '${newVal}' is an invalid discharge year`);
        }
    };

    const getDischargeDate = () => {
        onChange(`${dischargeDateYear}-${formatTwoDigitValues(dischargeDateMonth)}-${formatTwoDigitValues(dischargeDateDay)}`);
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab') {
            if (!event.shiftKey) {
                event.preventDefault();
                setFocus('dischargeDateYear');
            }
        }
    };

    return (
        <div className="sid-field sid-discharge-date">
            <div className="sid-l-space-top-md" />
            <label htmlFor="sid-discharge-date__month">
                <div className="sid-field__label-with-explanation sid-l-space-btm-sm">
                    <div className="sid-field__label sid-field__label--required">
                        <FormattedHTMLMessage
                            id="dischargeDate"
                            defaultMessage="Discharge date"
                        />
                    </div>
                    <div className="sid-field__label-explanation">
                        <FormattedHTMLMessage
                            id="verificationPurposesOnly"
                            defaultMessage="Used for verification purposes only"
                        />
                    </div>
                </div>
            </label>
            <div className="sid-discharge-date__inputs">
                <InputSelectComponent
                    className="sid-discharge-date__month"
                    fieldId="dischargeDate"
                    inputId="sid-discharge-date__month"
                    isErrored={isErrored}
                    options={months}
                    onKeyDown={event => handleOnKeyDown(event)}
                    onChange={
                        (choice) => {
                            if (choice) {
                                updateDischargeMonth(choice.value);
                                setFocus('dischargeDateYear');
                            } else {
                                // clear the discharge month
                                updateDischargeMonth(null);
                            }
                        }
                    }
                    placeholder={intl.formatMessage({ id: 'dateTime.month', defaultMessage: 'Month' })}
                    suppressPlaceholder={false}
                    value={dischargeDateMonth ? months[dischargeDateMonth - 1] : undefined}
                />

                <InputTextComponent
                    className="sid-discharge-date__year"
                    id="discharge-date-year"
                    isErrored={isErrored}
                    max={thisYear}
                    min={minDischargeYear}
                    onBlur={getDischargeDate}
                    onChange={e => updateDischargeYear(e.target.value)}
                    pattern="\d*"
                    placeholder={intl.formatMessage({ id: 'dateTime.year', defaultMessage: 'Year' })}
                    hidePlaceholder={false}
                    refId="dischargeDateYear"
                    required
                    value={dischargeDateYear || ''}
                />
            </div>

            {
                isErrored ? (
                    <div className="sid-field-error">
                        <FormattedHTMLMessage
                            id="errorId.invalidDischargeDate"
                            defaultMessage="Invalid discharge date"
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

DischargeDate.defaultProps = {
    value: '2000-01-01',
};

export const DischargeDateComponent = injectIntl(DischargeDate);
