import React from 'react';
import {
    injectIntl, InjectedIntl, FormattedHTMLMessage, FormattedMessage,
} from 'react-intl';

import {
    VerificationService,
    ErrorId,
    ErrorResponse,
} from '../lib/types/types';
import { PoweredByComponent } from './PoweredBy/PoweredByComponent';
import { TryAgainButtonComponent } from './TryAgainButtonComponent';
import { LogoComponent } from './LogoComponent/LogoComponent';
import { getLogoUrl } from '../lib/ProgramTheme/programThemeGetters';
import { requestOrganizationConstants as constants } from '../constants';
import { getLocaleSafely } from '../lib/intl/intl';
import { recordEvent } from '../lib/GoogleAnalytics/ga';
import { VerificationStepsEnum } from '../lib/types/runtimeTypes';
import { getSafe } from '../lib/utils/objects';
import { LinkExternal } from './LinkExternal';

interface StepErrorProps {
    verificationService: VerificationService;
    errorId?: ErrorId; // eslint-disable-line
    intl?: InjectedIntl; // eslint-disable-line
}

const BtnContainer = ({ children }: { children: JSX.Element | string }) => <div className="sid-l-space-top-lg sid-l-half-width sid-l-horz-center">{children}</div>;

const StepError = ({ verificationService, errorId }: StepErrorProps) => {
    const verificationResponse = verificationService.verificationResponse as ErrorResponse;
    const hasLogo = !!getLogoUrl(verificationService.programTheme);
    let errorIds: ErrorId[] = getSafe(() => verificationResponse.errorIds) || [];

    if (errorId) {
        errorIds = [errorId, ...errorIds]; // Prefer passed in err over any others, so it goes first
    }

    // The first is considered the most important, and will drive the logic on this step
    const firstErrorId = getSafe(() => errorIds[0], 'error');

    // defaults
    let errorTitle: FormattedMessage.Props = { id: 'error', defaultMessage: 'Error' };
    let logoContainerClass = 'sid-l-horz-center';
    let button = (<BtnContainer><TryAgainButtonComponent verificationService={verificationService} /></BtnContainer>);

    // special case
    if (errorIds.includes('verificationLimitExceeded')) {
        button = null;
        logoContainerClass += ' sid-l-space-top-xl';
    }


    // If there's an overriding title message, use it (UX-160):
    if (getSafe(() => verificationService.messages[`step.error.errorId.${firstErrorId}.title`])) {
        errorTitle = {
            id: `step.error.errorId.${firstErrorId}.title`,
            defaultMessage: verificationService.messages[`step.error.errorId.${firstErrorId}.title`],
        };
    }

    // If there are the proper ingredients to override the button, do that too (UX-160):
    if (getSafe(() => verificationService.messages[`step.error.errorId.${firstErrorId}.buttonText`])
        && getSafe(() => verificationResponse.redirectUrl)) {
        button = (
            <BtnContainer>
                <LinkExternal
                    href={verificationResponse.redirectUrl}
                    onClick={() => recordEvent(VerificationStepsEnum.error, verificationResponse.redirectUrl)}
                    className="sid-btn sid-btn-light"
                >
                    <FormattedHTMLMessage
                        id={`step.error.errorId.${firstErrorId}.buttonText`}
                        defaultMessage={verificationService.messages[`step.error.errorId.${firstErrorId}.buttonText`]}
                    />
                </LinkExternal>
            </BtnContainer>
        );
    }

    return (
        <div id="sid-step-error" className="sid-l-container">
            <div className="sid-header sid-l-horz-center">
                <div className={logoContainerClass}>
                    { hasLogo
                        ? <LogoComponent verificationService={verificationService} />
                        : (
                            <img
                                className="sid-l-lead-image"
                                alt="design element"
                                src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/circle-exclamation.svg"
                            />
                        )}
                </div>
                <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
                    <FormattedHTMLMessage {...errorTitle} />
                </div>
                <div className="sid-header__subtitle sid-l-horz-center sid-l-space-top-md">
                    <div className="sid-error-msg sid-l-horz-center">
                        { errorIds
                            ? (
                                <p>
                                    {
                                        errorIds.map(
                                            errId => (
                                                <FormattedHTMLMessage
                                                    key={errId}
                                                    id={`errorId.${errId}`}
                                                    defaultMessage={errId}
                                                    values={{
                                                        feedbackUrl: constants.FEEDBACK_FORM_URL,
                                                        programId: verificationService.programId || '',
                                                        locale: getLocaleSafely(verificationService.viewModel, verificationResponse).replace('-', '_'),
                                                    }}
                                                />
                                            ),
                                        )
                                    }
                                </p>
                            )
                            : null }
                    </div>
                    { button }
                </div>
            </div>

            <div className="sid-footer sid-l-horz-center sid-l-space-top-xl sid-h-small-text">
                <PoweredByComponent verificationService={verificationService} isCentered />
            </div>
        </div>
    );
};

export const StepErrorComponent = injectIntl(StepError);
