import React from 'react';
import {
    injectIntl, FormattedHTMLMessage, FormattedMessage, InjectedIntl,
} from 'react-intl';
import { produce } from 'immer';


import { logger } from '../../lib/utils/logger/logger';
import {
    VerificationService, StudentPersonalInfoViewModel, SMSLoopResponse, SMSLoopViewModel,
} from '../../lib/types/types';
import { VerificationStepsEnum } from '../../lib/types/runtimeTypes';
import { getSafe } from '../../lib/utils/objects';

import { SMSCodeComponent } from '../FormFields/SMSCode/SMSCodeComponent';

import { VerificationApiClient } from '../../lib/ServerApi/VerificationApiClient';


interface StepSMSLoopProps {
    intl: InjectedIntl;
    verificationService: VerificationService;
}

const StepSMSLoop = ({ intl, verificationService }: StepSMSLoopProps) => {
    const viewModel = verificationService.viewModel as SMSLoopViewModel;
    const verificationResponse = verificationService.verificationResponse as SMSLoopResponse;
    const isErrored = Boolean(verificationService.fieldValidationErrors.smsCode);
    const previousViewModel = (verificationService.previousViewModel as StudentPersonalInfoViewModel) || undefined;

    const [stepResultMessage, setStepResultMessage] = React.useState('');
    const [isAttemptLimitExceeded, setAttemptLimitExceeded] = React.useState(verificationResponse.errorIds && verificationResponse.errorIds.includes('incorrectSMSCodeAttemptLimitExceeded'));

    const stepSuccessMessage = intl.formatMessage({ id: 'step.smsLoop.successResend', defaultMessage: 'SMS message re-sent successfully' });
    const maxTotalRetriesMessage = intl.formatMessage({ id: 'step.smsLoop.errors.codeResendLimit', defaultMessage: 'Maximum number of re-tries has been reached.' });
    const maxRetriesPerCodeMessage = intl.formatMessage({ id: 'step.smsLoop.errors.codeResendLimit', defaultMessage: 'Maximum number of re-tries has been reached.' });
    const errorResendingSMSMessage = intl.formatMessage({ id: 'step.smsLoop.errors.resend', defaultMessage: 'Error sending the SMS message' });

    React.useEffect(() => {
        if (isAttemptLimitExceeded) {
            setStepResultMessage(maxRetriesPerCodeMessage);
        }
    }, []);

    if (getSafe(() => verificationResponse.errorIds.length) && !verificationService.fieldValidationErrors.smsCode) {
        verificationService.updateFieldValidationErrors({ ...verificationService.fieldValidationErrors, smsCode: verificationResponse.errorIds[0] });
    }

    const updateSmsCode = (value) => {
        const nextState: SMSLoopViewModel = produce(viewModel, (draft: SMSLoopViewModel) => {
            draft.smsCode = value;
            draft.phoneNumber = previousViewModel && previousViewModel.phoneNumber;
        });
        verificationService.updateViewModel(nextState);
    };

    const submitForm = () => {
        logger.info('StepSMSLoop submitting form');
        verificationService.submitStep(VerificationStepsEnum.smsLoop, viewModel, verificationService.verificationResponse);
    };

    const resendSmsCode = async (verificationId) => {
        const response = await VerificationApiClient.getResendNewSmsCode(verificationId);
        if (response) {
            setAttemptLimitExceeded(false);
            setStepResultMessage(response.status === 204 ? stepSuccessMessage : maxTotalRetriesMessage);
        } else {
            setStepResultMessage(errorResendingSMSMessage);
        }
    };

    return (
        <div id="sid-step-sms-loop" className="sid-l-container">
            <div className="sid-header__title sid-l-horz-center">
                <FormattedMessage
                    id="step.smsLoop.verificationCode"
                    defaultMessage="Verification Code"
                />
            </div>
            <div className="sid-header__subtitle">
                {
                    getSafe(() => previousViewModel.phoneNumber) ? (
                        <FormattedMessage
                            id="step.smsLoop.titleWithNumber"
                            defaultMessage="We've sent an SMS message to the mobile number ending in {number}. Enter your code here."
                            values={{
                                number: getSafe(() => previousViewModel.phoneNumber.slice(-4), ''),
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="step.smsLoop.titleWithoutNumber"
                            defaultMessage="We've sent an SMS to your mobile number. Please enter the code here."
                        />
                    )
                }
            </div>

            <div className="sid-sms-code-id-wrapper sid-l-horz-center">
                <SMSCodeComponent
                    onChange={updateSmsCode}
                    value={viewModel.smsCode}
                    isErrored={isErrored}
                    errorId={verificationService.fieldValidationErrors.smsCode}
                />
            </div>

            {
                stepResultMessage && (
                    <div>
                        <p>
                            {stepResultMessage}
                        </p>
                    </div>
                )
            }
            <div className="sid-submit sid-l-space-top-md sid-l-horz-center">
                <button
                    id="sid-submit-sms-code"
                    onClick={submitForm}
                    type="button"
                    className="sid-btn sid-btn--dark sid-submit__continue"
                    aria-label="submit"
                    disabled={!!((!viewModel.smsCode || viewModel.smsCode.length <= 0 || isAttemptLimitExceeded))}
                >
                    <FormattedMessage id="step.smsLoop.submitButton" defaultMessage="Submit" />
                </button>
                <div className="sid-l-space-left-sm">&nbsp;</div>
                <button
                    id="sid-resend-sms-code"
                    onClick={() => resendSmsCode(verificationResponse.verificationId)}
                    type="submit"
                    className="sid-btn sid-btn--light sid-submit__cancel"
                    aria-label="re-send"
                >
                    <FormattedMessage id="step.smsLoop.resendButton" defaultMessage="Re-send" />
                </button>
            </div>
            <div className="sid-incorrect-number sid-l-horz-center">
                <FormattedMessage id="step.smsLoop.incorrectNumber.incorrectNumber1" defaultMessage="Incorrect number? Click " />
                <button type="button" onClick={() => window.location.reload()} className="sid-incorrect-number__refresh-link">
                    <FormattedHTMLMessage id="step.smsLoop.incorrectNumber.incorrectNumber2" defaultMessage="here " />
                </button>
                <FormattedMessage id="companyName" defaultMessage="{Company}">
                    {companyName => (
                        <FormattedMessage
                            id="step.smsLoop.incorrectNumber.incorrectNumber3"
                            defaultMessage="to return to {companyName}"
                            values={{ companyName }}
                        />
                    )}
                </FormattedMessage>
            </div>
        </div>
    );
};

export const StepSMSLoopComponent = injectIntl(StepSMSLoop);
