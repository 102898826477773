import React from 'react';
import { NewInnerRequestOrganizationFormComponent } from './NewRequestOrganizationInnerComponent';
import {
    DatabaseId, RequestOrganizationService, RequestOrganizationViewModel, RequestOrganizationMessages, GenericAction,
} from '../../lib/types/types';
import { RequestOrganizationContext } from '../../lib/RequestOrganizationService/RequestOrganizationStore';
import { getRequestOrganizationLocale, getRequestOrgMessages } from '../../lib/intl/intl';
import { VerificationApiClient } from '../../lib/ServerApi/VerificationApiClient';


interface NewRequestOrganizationFormComponentProps {
    programId: DatabaseId;
    viewModel?: RequestOrganizationViewModel; // eslint-disable-line
}

export const NewRequestOrganizationFormComponent = ({ programId, viewModel }: NewRequestOrganizationFormComponentProps) => {
    const [requestOrganizationService, dispatch]: [RequestOrganizationService, Function] = React.useContext(RequestOrganizationContext);
    const { locale } = requestOrganizationService;
    const { messages } = requestOrganizationService;

    React.useEffect(() => {
        if (!requestOrganizationService.locale) {
            const locale = getRequestOrganizationLocale();
            dispatch({ type: 'SET_LOCALE', payload: locale });
            dispatch({
                type: 'UPDATE_VIEW_MODEL',
                payload: {
                    localeChoice: {
                        value: locale,
                        label: '',
                    },
                },
            });
        }
        if (!requestOrganizationService.programId) {
            dispatch({ type: 'PROGRAM_ID', payload: programId });
        }
    }, []);

    React.useEffect(() => {
        if (requestOrganizationService.programId) {
            const fetchTheme = async () => {
                const result = await VerificationApiClient.fetchProgramTheme(requestOrganizationService.programId);
                dispatch({ type: 'PROGRAM_THEME', payload: result });
            };
            fetchTheme();
        }
    }, [requestOrganizationService.programId]);

    React.useEffect(() => {
        const fetchMessages = async () => {
            dispatch({ type: 'SET_LOADING', payload: true });
            const messages: RequestOrganizationMessages = await getRequestOrgMessages(requestOrganizationService.locale);
            const messagesAction: GenericAction = { payload: messages, type: 'SET_MESSAGES' };
            dispatch(messagesAction);
            dispatch({ type: 'SET_LOADING', payload: false });
        };

        if (requestOrganizationService.locale) {
            fetchMessages();
        }
    }, [requestOrganizationService.locale]);

    React.useEffect(() => {
        if (requestOrganizationService.locale
            && requestOrganizationService.programTheme && !requestOrganizationService.isInitialized) {
            dispatch({ type: 'SET_INITIALIZED', payload: true });
            dispatch({ type: 'SET_LOADING', payload: false });

            if (viewModel) {
                dispatch({ type: 'UPDATE_VIEW_MODEL', payload: viewModel });
            }
        }
    }, [requestOrganizationService]);

    if (!messages) {
        return null;
    }

    return (
        <NewInnerRequestOrganizationFormComponent
            locale={locale}
            messages={messages}
            requestOrganizationService={requestOrganizationService}
        />
    );
};

