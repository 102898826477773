import { InjectedIntl } from 'react-intl';
import {
    Country,
    EstimatedReviewTime,
    FaqUrl,
    Locale,
    MarketConsentOptions,
    MaxReviewTime,
    MetadataConfig,
    Options,
    ProgramTheme,
    ProgramThemeMessages,
    Segment,
    State,
    VerificationServiceValues,
} from '../types/types';
import { logger } from '../utils/logger/logger';
import { getOptions } from '../../options/options';
import { arrayUnique } from '../utils/arrays';
import { deepClone, getSafe } from '../utils/objects';
import { DEFAULT_PRIVACY_POLICY_HOST, SHEERID } from '../../constants';
import { StateEnum } from '../types/runtimeTypes';
import { standardizeLocale } from '../intl/intl';

export const getCompanyName = (programTheme: ProgramTheme): string => {
    let companyName = '{Company}'; // Show literal string "{Company}" so implementor is prompted to fix by adding in my.sheerid.com
    try {
        companyName = programTheme.intl.messages.companyName;
        if (!(typeof companyName === 'string' && companyName.length > 0)) {
            throw new Error('Invalid companyName');
        }
    } catch (e) {
        // Property access or invalid company name error
        logger.warn('companyName not found. Please sign in to my.sheerid.com to set.');
    }

    return companyName;
};

const defaultMarketConsent: MarketConsentOptions = {
    enabled: false,
    required: false,
    message: '',
};

const defaultMetadata: MetadataConfig = Object.freeze({
    enabled: false,
    keys: [],
});

export const getMetadataConfig = (programTheme: ProgramTheme): MetadataConfig => {
    let customMetadata: MetadataConfig = deepClone(defaultMetadata);

    try {
        if (getSafe<boolean>(() => programTheme.config.customMetadata.enabled)) {
            customMetadata = programTheme.config.customMetadata;
        }
    } catch (e) {
        logger.warn('No custom metadata provided by the server.');
    }
    if (getOptions().customMetadata) {
        customMetadata = getOptions().customMetadata;
    }

    return customMetadata;
};

export const getMarketConsent = (programTheme: ProgramTheme): MarketConsentOptions => {
    let marketConsent: MarketConsentOptions = { ...defaultMarketConsent };
    try {
        if (programTheme.config.marketConsent) {
            marketConsent = programTheme.config.marketConsent;
        }
    } catch (e) {
        // market consent is not coming for this program from server. Default will be used
        logger.warn('Server did not provided a market consent config. Default will be used');
    }
    if (getOptions().marketConsent) {
        marketConsent = getOptions().marketConsent;
    }
    return marketConsent;
};

export const getThemeMessages = (programTheme: ProgramTheme): ProgramThemeMessages => {
    let themeMessages: ProgramThemeMessages;
    try {
        themeMessages = programTheme.intl.messages;
    } catch (e) {
        logger.warn('Unable to get theme messages from ', programTheme);
    }
    return themeMessages;
};

export const getCustomCss = (programTheme: ProgramTheme): ProgramTheme['customCss'] => getOptions().customCss || programTheme.customCss;

export const getLogoUrl = (programTheme: ProgramTheme): ProgramTheme['logoUrl'] => {
    const options = getOptions();

    return options.hasOwnProperty('logoUrl') ? options.logoUrl : programTheme.logoUrl;
};

export const getPrivacyPolicyUrl = (programTheme: ProgramTheme): ProgramTheme['privacyPolicyUrl'] => {
    const options = getOptions();

    return options.privacyPolicyUrl || programTheme.privacyPolicyUrl;
};

export const getPrivacyPolicyCompanyName = (programTheme: ProgramTheme, intl: InjectedIntl): string => {
    let privacyPolicyHostName = '';
    try {
        privacyPolicyHostName = new URL(getPrivacyPolicyUrl(programTheme)).hostname;
    } catch (_) {
        logger.warn('Invalid Privacy Policy URL ', privacyPolicyHostName);
    }
    if (privacyPolicyHostName === DEFAULT_PRIVACY_POLICY_HOST) {
        return SHEERID;
    }
    return intl.formatMessage({
        id: 'companyName',
        defaultMessage: '{companyName}',
    });
};

/**
 * @description Return locales configured in MySheerID for this program, or specified in Javascript options.
 */
export const getAvailableLocales = (programTheme: ProgramTheme): Locale[] => {
    let configuredLocales: Locale[] = ['en-US'];
    let themeLocales: Locale[] = [];
    try {
        themeLocales = programTheme.config.locales;
    } catch (e) {
        logger.warn('Unable to get configured locales from programTheme');
    }
    const optionsLocales: Locale[] = getLocalesFromOptions(getOptions());
    configuredLocales = [...themeLocales, ...optionsLocales];

    return arrayUnique(configuredLocales);
};

/**
 * @private
 */
export const getLocalesFromOptions = (options: Options): Locale[] => {
    if (options && options.messagesWithLocale) {
        return Object.keys(options.messagesWithLocale) as Locale[];
    }
    return [];
};

/**
 * @description Safely return the countries that were chosen/configured in MySheerID for this program.
 * Otherwise, return US as default.
 */
export const getConfiguredCountries = (programTheme: ProgramTheme): Country[] => {
    try {
        if (programTheme && programTheme.config && programTheme.config.countries) {
            return programTheme.config.countries;
        }

        // Old location for this property, may be returned from the server
        // @ts-ignore
        if (programTheme.intl.messages.step.personalInfo.countries) {
            const availableCountries: Country[] = [];

            // @ts-ignore
            for (const countryCode in programTheme.intl.messages.step.personalInfo.countries) { // eslint-disable-line
                availableCountries.push(countryCode as Country);
            }
            return availableCountries;
        }
    } catch (e) {
        logger.warn('Unable to get configured countries');
    }
    return ['US'];
};

/**
 * @description Safely return the states that were chosen/configured in MySheerID for this program.
 */
export const getConfiguredStates = (programTheme: ProgramTheme): State[] => {
    try {
        if (programTheme && programTheme.config && programTheme.config.states && programTheme.config.states.length > 0) {
            return programTheme.config.states;
        }
    } catch (e) {
        logger.warn('Unable to get configured states');
    }
    return Object.keys(StateEnum) as State[];
};

export const getEstimatedReviewTime = (programTheme: ProgramTheme): EstimatedReviewTime => {
    let ewt: EstimatedReviewTime;
    try {
        // Added in API ver 2.5.x, may not be present for all
        ewt = programTheme.config.estimatedReviewTime;
    } catch (e) {
        ewt = 'A_FEW_HOURS';
    }
    return ewt;
};

export const getMaxReviewTime = (programTheme: ProgramTheme): MaxReviewTime => {
    let mrt: MaxReviewTime;
    try {
        // Added in API ver 2.5.x, may not be present for all
        mrt = programTheme.config.maxReviewTime;
    } catch (e) {
        mrt = '2_HRS';
    }
    return mrt;
};

const options = getOptions();
const getFaqUrl = (): string => {
    const keyName: keyof Options = 'urlFaq';
    if (!options.hasOwnProperty(keyName)) {
        logger.error(`Can't find an option for '${keyName}'`, 'Option not found');
    }

    return options[keyName];
};

export const getFaqLink = (
    programTheme: ProgramTheme,
    segment: Segment,
    programId: VerificationServiceValues['programId'],
    locale: Locale,
): FaqUrl => {
    let faqInTheme: FaqUrl;
    try {
        faqInTheme = programTheme.config.customFaqLink;
    } catch (e) {
        faqInTheme = '';
    }

    return (!faqInTheme || faqInTheme === '')
        ? `${getFaqUrl()}?pid=${programId}&locale=${standardizeLocale(locale)}`
        : faqInTheme;
};

export const getOrgSearchCountryTags = (programTheme: ProgramTheme, country: Country): string => getSafe(() => programTheme.config.orgSearchCountryTags[country].join(','), '');
