import React, { useEffect } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { produce } from 'immer';

import {
    VerificationService,
    MoverPersonalInfoViewModel,
    MoverPersonalInfoResponse,
} from '../../lib/types/types';

import { VerificationStepsEnum } from '../../lib/types/runtimeTypes';
import { hasFailedInstantMatch } from '../../lib/VerificationService/VerificationServiceHelpers';
import { isFormFilled } from '../../lib/validators/validators';
import { setRef } from '../../lib/refs/refs';
import {
    handleEmailOnKeyDown,
    submitForm,
    updateFieldValidationErrorsByFieldId,
} from '../../lib/utils/stepComponentHelpers/stepComponentHelpers';

import { MarketConsentWrapperComponent as MarketConsentWrapper } from '../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent';
import { LogoComponent } from '../LogoComponent/LogoComponent';
import { PhoneNumberComponent as PhoneNumber } from '../FormFields/PhoneNumber/PhoneNumberComponent';
import { FirstNameComponent as FirstName } from '../FormFields/FirstName/FirstNameComponent';
import { LastNameComponent as LastName } from '../FormFields/LastName/LastNameComponent';
import { AddressComponent as Address } from '../FormFields/Address/AddressComponent';
import { PostalCodeComponent as PostalCode } from '../FormFields/PostalCode/PostalCodeComponent';
import { EmailComponent as Email } from '../FormFields/Email/EmailComponent';
import { FormFooterComponent as FormFooter } from '../FormFooter/FormFooterComponent';
import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from '../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent';
import { RewardsRemainingComponent } from '../RewardsRemaining/RewardsRemainingComponent';

interface StepMoverPersonalInfoComponentProps {
    verificationService: VerificationService;
}

const StepMoverPersonalInfo = ({ verificationService }: StepMoverPersonalInfoComponentProps) => {
    const viewModel = verificationService.viewModel as MoverPersonalInfoViewModel;
    const { fieldValidationErrors } = verificationService;
    const verificationResponse = verificationService.verificationResponse as MoverPersonalInfoResponse;
    const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

    const updateMoverViewModel = (key: keyof MoverPersonalInfoViewModel, value: any) => {
        const nextState: MoverPersonalInfoViewModel = produce(viewModel, (draft: MoverPersonalInfoViewModel) => {
            (draft[key] as any) = value;
        });
        verificationService.updateViewModel(nextState);
    };

    // We do not want a drop down list, just apply available statuses to the viewModel
    useEffect(() => {
        updateMoverViewModel('statuses', verificationResponse.availableStatuses);
    }, []);

    return (
        <div id="sid-step-mover-personal-info" className="sid-l-container">
            {failedInstantMatch
                ? (
                    <div className="sid-header">
                        <div className="sid-l-horz-center">
                            <LogoComponent verificationService={verificationService} />
                        </div>
                        <div className="sid-header__title sid-l-horz-center">
                            <FormattedHTMLMessage id="step.personalInfo.tryAgain.title" defaultMessage="Check Your Name & Address" />
                        </div>

                        <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
                            <FormattedHTMLMessage
                                id="step.personalInfo.tryAgain.subtitle"
                                defaultMessage="Your full name and address must match what is on your official documentation."
                            />
                        </div>
                    </div>
                )
                : (
                    <div className="sid-header">
                        <div className="sid-l-horz-center">
                            <LogoComponent verificationService={verificationService} />
                        </div>
                        <div className="sid-header__title sid-l-horz-center">
                            <FormattedHTMLMessage id="step.personalInfo.title" defaultMessage="Unlock this Special Offer" />
                        </div>

                        <div className="sid-header__subtitle sid-l-horz-center">
                            <FormattedHTMLMessage
                                id="step.personalInfo.subtitle"
                                defaultMessage="Verify you have recently moved."
                            />
                            &nbsp;
                            <HowDoesVerifyingWork verificationService={verificationService} />
                            <RewardsRemainingComponent verificationService={verificationService} />
                        </div>
                    </div>
                )}

            <div className="sid-names">
                <FirstName
                    value={viewModel.firstName}
                    isErrored={!!fieldValidationErrors.firstName}
                    onChange={
                        (newValue) => {
                            updateMoverViewModel('firstName', newValue);
                            updateFieldValidationErrorsByFieldId('firstName', newValue, verificationService);
                        }
                    }
                />

                <LastName
                    value={viewModel.lastName}
                    isErrored={!!fieldValidationErrors.lastName}
                    onChange={
                        (newValue) => {
                            updateMoverViewModel('lastName', newValue);
                            updateFieldValidationErrorsByFieldId('lastName', newValue, verificationService);
                        }
                    }
                />
            </div>

            <Address
                value={viewModel.address1}
                isErrored={!!fieldValidationErrors.address1}
                errorId={fieldValidationErrors.address1}
                onChange={(newValue) => {
                    updateMoverViewModel('address1', newValue);
                    updateFieldValidationErrorsByFieldId('address1', newValue, verificationService);
                }}
            />

            <PostalCode
                value={viewModel.postalCode}
                isErrored={!!fieldValidationErrors.postalCode}
                onChange={(newValue) => {
                    updateMoverViewModel('postalCode', newValue);
                    updateFieldValidationErrorsByFieldId('postalCode', newValue, verificationService);
                }}
            />

            <Email
                value={viewModel.email}
                isErrored={!!fieldValidationErrors.email}
                explanation={(
                    <FormattedHTMLMessage
                        id="emailExplanation"
                        defaultMessage="Needed to send you your unique code"
                    />
                )}
                onChange={
                    (newValue) => {
                        updateMoverViewModel('email', newValue);
                        updateFieldValidationErrorsByFieldId('email', newValue, verificationService);
                    }
                }
                onKeyDown={event => handleEmailOnKeyDown(event)}
            />

            {
                verificationService.programTheme.isSmsNotifierConfigured || verificationService.programTheme.smsLoopEnabled
                    ? (
                        <PhoneNumber
                            isRequired={!!verificationService.programTheme.smsLoopEnabled}
                            value={viewModel.phoneNumber}
                            isErrored={!!fieldValidationErrors.phoneNumber}
                            onChange={
                                (newValue) => {
                                    updateMoverViewModel('phoneNumber', newValue);
                                    updateFieldValidationErrorsByFieldId('phoneNumber', newValue, verificationService);
                                }
                            }
                        />
                    )
                    : null
            }

            <MarketConsentWrapper
                verificationService={verificationService}
                isErrored={!!fieldValidationErrors.marketConsentValue}
                onChange={(newValue) => {
                    updateMoverViewModel('metadata', { ...verificationService.viewModel.metadata, marketConsentValue: newValue });
                    updateFieldValidationErrorsByFieldId('marketConsentValue', newValue, verificationService);
                }}
                viewModel={viewModel}
            />

            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
                <div className="sid-submit">
                    <button
                        id="sid-submit-wrapper__collect-info"
                        onClick={() => submitForm(viewModel, verificationService, VerificationStepsEnum.collectMoverPersonalInfo)}
                        type="submit"
                        className={`sid-btn sid-btn--dark sid-l-full-width ${!isFormFilled(viewModel, verificationService.formValidationOptions) ? 'sid-btn--disabled-like' : ''}`}
                        aria-label="submit"
                        aria-labelledby="verify-status-text"
                        ref={button => setRef('submitButton', button)}
                    >
                        <span id="verify-status-text">
                            {failedInstantMatch
                                ? <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
                                : <FormattedHTMLMessage id="verifyMyMoverStatus" defaultMessage="Verify My Recent Mover Status" />}
                        </span>
                    </button>
                </div>
            </div>

            <FormFooter verificationService={verificationService} />
        </div>
    );
};

export const StepMoverPersonalInfoComponent = injectIntl(StepMoverPersonalInfo);
