import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormFieldComponentProps } from '../../../lib/types/types';
import { InputTextComponent } from '../../FormInputs/InputText/InputText';

export const SMSCode = ({
    onChange,
    value,
    isErrored,
    errorId,
    intl,
    placeholder = '',
}: FormFieldComponentProps) => {
    const getErrorMessage = (errorId) => {
        switch (errorId) {
            case 'invalidSMSCode':
                return (
                    <FormattedMessage
                        id="step.smsLoop.errors.codeVerification"
                        defaultMessage="Verification code could not be validated."
                    />
                );
            case 'expiredSMSCode':
                return (
                    <FormattedMessage
                        id="step.smsLoop.errors.codeExpired"
                        defaultMessage="Verification code expired."
                    />
                );
            default:
                return (
                    <FormattedMessage
                        id="errorId.unknownError"
                        defaultMessage="Unknown Error"
                    />
                );
        }
    };

    return (
        <div className="sid-field sid-sms-code-id">
            <div className="sid-l-space-top-md" />
            {
                isErrored ? (
                    <div className="sid-field-error">
                        {getErrorMessage(errorId)}
                    </div>
                ) : null
            }

            <InputTextComponent
                id="sms-code"
                isErrored={isErrored}
                required
                placeholder={placeholder || intl.formatMessage({ id: 'smsCodePlaceholder', defaultMessage: 'SMS Code*' })}
                value={value}
                onChange={e => onChange(e.target.value)}
            />

        </div>
    );
};

export const SMSCodeComponent = injectIntl(SMSCode);
