import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

interface SearchFieldComponentProps {
    name: string;
    label: string;
    value: string;
    placeholder: string;
    isErrored: string;
    onChange: Function;
    onKeyDown: Function;
    search: Function;
}

export const SearchFieldComponent = ({
    name,
    label,
    value,
    placeholder,
    isErrored,
    onChange,
    onKeyDown,
    search,
}: SearchFieldComponentProps) => {
    const handleOnKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            search();
        } else if (onKeyDown) {
            onKeyDown();
        }
    };

    return (
        <div className="sid-field">
            <label htmlFor={`sid-${name}`}>
                <div className="sid-field__label sid-l-space-btm-sm">{ label }</div>
            </label>
            <div className="sid-field-search">
                <input
                    className={
                        `sid-text-input sid-text-input--required sid-field-search-input
                        sid-l-full-width ${isErrored ? 'sid-text-input--error' : ''}`
                    }
                    id={`sid-${name}`}
                    name={`sid-${name}`}
                    type="text"
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    onKeyDown={e => handleOnKeyDown(e)}
                    placeholder={placeholder}
                />
                <div
                    tabIndex={0}
                    onClick={() => search()}
                    onKeyUp={() => search()}
                    role="button"
                >
                    <img
                        className="sid-l-search-icon"
                        src="https://s3.amazonaws.com/com.sheerid.resources/common/images/requestOrganization/search.svg"
                        alt="Search"
                    />
                </div>
            </div>
            {
                isErrored
                    ? (
                        <div className="sid-field-error">
                            <span>
                                <FormattedHTMLMessage
                                    id={`errorId.${isErrored}`}
                                    defaultMessage="An unexpected error occurred"
                                />
                            </span>
                        </div>
                    )
                    : null
            }
        </div>
    );
};
