import React from 'react';

type Props = {
    children: JSX.Element | JSX.Element[] | string | null;
};

// 1. Always open external links with target="_blank" so they work from the iFrame jslib is commonly (but not always) running within
// 2. When using target="_blank", always add rel noopener noreferrer for security.
//    https://stackoverflow.com/questions/50709625/link-with-target-blank-and-rel-noopener-noreferrer-still-vulnerable
export const LinkExternal = ({ children, ...restProps }: Props & React.AnchorHTMLAttributes<HTMLAnchorElement>) => <a target="_blank" rel="noopener noreferrer" {...restProps}>{children}</a>;
