import React from 'react';
import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { logger } from '../../../lib/utils/logger/logger';
import { FormFieldComponentProps, FormSelectChoice, InputSelectOnKeyDown } from '../../../lib/types/types';
import { InputSelectComponent } from '../../FormInputs/InputSelect/InputSelectComponent';
import { setFocus } from '../../../lib/utils/browser/inputHelpers';
import { setRef } from '../../../lib/refs/refs';
import { formatTwoDigitValues } from '../../../lib/utils/datesFormat/formatTwoDigitValues';

export const BirthDate = ({
    onChange,
    intl,
    isErrored,
    isRequired = true,
    errorId,
    value,
}: FormFieldComponentProps & InjectedIntlProps) => {
    const birthDateParts = value.split('-');
    const birthDateYear = Number.parseInt(birthDateParts[0], 10) || undefined;
    const birthDateMonth = Number.parseInt(birthDateParts[1], 10) || undefined;
    const birthDateDay = Number.parseInt(birthDateParts[2], 10) || undefined;

    const minBirthYear = 1900;
    const thisYear = new Date().getFullYear();

    const months: FormSelectChoice[] = [
        { value: '1', label: intl.formatMessage({ id: 'dateTime.january', defaultMessage: 'January' }) },
        { value: '2', label: intl.formatMessage({ id: 'dateTime.february', defaultMessage: 'February' }) },
        { value: '3', label: intl.formatMessage({ id: 'dateTime.march', defaultMessage: 'March' }) },
        { value: '4', label: intl.formatMessage({ id: 'dateTime.april', defaultMessage: 'April' }) },
        { value: '5', label: intl.formatMessage({ id: 'dateTime.may', defaultMessage: 'May' }) },
        { value: '6', label: intl.formatMessage({ id: 'dateTime.june', defaultMessage: 'June' }) },
        { value: '7', label: intl.formatMessage({ id: 'dateTime.july', defaultMessage: 'July' }) },
        { value: '8', label: intl.formatMessage({ id: 'dateTime.august', defaultMessage: 'August' }) },
        { value: '9', label: intl.formatMessage({ id: 'dateTime.september', defaultMessage: 'September' }) },
        { value: '10', label: intl.formatMessage({ id: 'dateTime.october', defaultMessage: 'October' }) },
        { value: '11', label: intl.formatMessage({ id: 'dateTime.november', defaultMessage: 'November' }) },
        { value: '12', label: intl.formatMessage({ id: 'dateTime.december', defaultMessage: 'December' }) },
    ];

    const isDateFieldEmpty = (year, month, day) => (
        (!day || day === '')
            && (!month || month === '')
            && (!year || year === '')
    );

    const updateBirthMonth = (month) => {
        if (isDateFieldEmpty(birthDateYear, month, birthDateDay)) {
            onChange('');
            return;
        }

        if (month === '' || month === null) {
            onChange(`${birthDateYear}--${birthDateDay}`);
        }

        const newVal = Number.parseInt(month, 10);
        if (typeof newVal === 'number' && newVal >= 1 && newVal <= 12) {
            onChange(`${birthDateYear}-${formatTwoDigitValues(month)}-${formatTwoDigitValues(birthDateDay)}`);
        } else {
            logger.warn(`Value '${newVal}' is an invalid birth month`);
        }
    };

    const updateBirthDay = (day) => {
        if (isDateFieldEmpty(birthDateYear, birthDateMonth, day)) {
            onChange('');
            return;
        }

        if (day === '') {
            onChange(`${birthDateYear}-${birthDateMonth}-`);
        }
        const newVal = Number.parseInt(day, 10);
        if (typeof newVal === 'number' && newVal >= 1 && newVal <= 31) {
            onChange(`${birthDateYear}-${formatTwoDigitValues(birthDateMonth)}-${formatTwoDigitValues(newVal)}`);
        } else {
            logger.warn(`Value '${newVal}' is an invalid birth day`);
        }
    };

    const updateBirthYear = (year) => {
        if (isDateFieldEmpty(year, birthDateMonth, birthDateDay)) {
            onChange('');
            return;
        }

        if (year === '') {
            onChange(`-${birthDateMonth}-${birthDateDay}`);
        }
        const newVal = Number.parseInt(year, 10);
        if (typeof newVal === 'number' && newVal >= 1 && newVal <= thisYear) {
            onChange(`${newVal}-${formatTwoDigitValues(birthDateMonth)}-${formatTwoDigitValues(birthDateDay)}`);
        } else {
            logger.warn(`Value '${newVal}' is an invalid birth year`);
        }
    };

    const getBirthDate = () => {
        if (!birthDateDay && !birthDateMonth && !birthDateYear) {
            onChange('');
        } else {
            onChange(`${birthDateYear}-${formatTwoDigitValues(birthDateMonth)}-${formatTwoDigitValues(birthDateDay)}`);
        }
    };

    const handleOnKeyDown: InputSelectOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            setFocus('birthDateDay');
        }
    };

    const monthFieldId = 'sid-birthdate__month';

    const errMsg = errorId ? (
        <div className="sid-field-error">
            <FormattedHTMLMessage
                id={`errorId.${errorId}`}
                defaultMessage="Invalid birth date"
            />
        </div>
    )
        : (
            <div className="sid-field-error">
                <FormattedHTMLMessage
                    id="errorId.invalidBirthDate"
                    defaultMessage="Invalid birth date"
                />
            </div>
        );

    return (
        <div className="sid-field sid-birthdate">
            <div className="sid-l-space-top-md" />
            <label htmlFor={monthFieldId}>
                <div id={`${monthFieldId}-label`} className="sid-field__label-with-explanation sid-l-space-btm-sm">
                    <div id="sid-birthdate" className={`sid-field__label${isRequired ? ' sid-field__label--required' : ''}`}>
                        <FormattedHTMLMessage
                            id="birthDate"
                            defaultMessage="Date of birth"
                        />
                    </div>
                    <div className="sid-field__label-explanation">
                        <FormattedHTMLMessage
                            id="verificationPurposesOnly"
                            defaultMessage="Used for verification purposes only"
                        />
                    </div>
                </div>
            </label>

            <div className="sid-birthdate__inputs">
                <InputSelectComponent
                    className="sid-birthdate__month"
                    fieldId="birthDate"
                    inputId={monthFieldId}
                    isErrored={isErrored}
                    options={months}
                    onKeyDown={event => handleOnKeyDown(event)}
                    onChange={
                        (choice) => {
                            if (choice) {
                                updateBirthMonth(choice.value);
                                setFocus('birthDateDay');
                            } else {
                                // clear the birth month
                                updateBirthMonth(null);
                            }
                        }
                    }
                    placeholder={intl.formatMessage({ id: 'dateTime.month', defaultMessage: 'Month' })}
                    suppressPlaceholder={false}
                    value={birthDateMonth ? months[birthDateMonth - 1] : undefined}
                    isRequired={isRequired}
                />

                <input
                    className={`sid-birthdate__day sid-text-input sid-text-input--required ${isErrored ? 'sid-text-input--error' : ''}`}
                    id="sid-birthdate-day"
                    min={1}
                    max={31}
                    name="sid-birthdate-day"
                    onChange={e => updateBirthDay(e.target.value)}
                    onBlur={getBirthDate}
                    pattern="\d*"
                    placeholder={intl.formatMessage({ id: 'dateTime.day', defaultMessage: 'Day' })}
                    aria-label={intl.formatMessage({ id: 'dateTime.day', defaultMessage: 'Day' })}
                    aria-labelledby="sid-birthdate"
                    aria-required={isRequired}
                    ref={input => setRef('birthDateDay', input)}
                    type="text"
                    value={birthDateDay || ''}
                />

                <input
                    className={`sid-birthdate__year sid-text-input sid-text-input--required ${isErrored ? 'sid-text-input--error' : ''}`}
                    id="sid-birthdate-year"
                    name="sid-birthdate-year"
                    type="text"
                    min={minBirthYear}
                    max={thisYear}
                    value={birthDateYear || ''}
                    pattern="\d*"
                    placeholder={intl.formatMessage({ id: 'dateTime.year', defaultMessage: 'Year' })}
                    aria-label={intl.formatMessage({ id: 'dateTime.year', defaultMessage: 'Year' })}
                    aria-labelledby="sid-birthdate"
                    aria-required={isRequired}
                    ref={input => setRef('birthDateYear', input)}
                    onChange={e => updateBirthYear(e.target.value)}
                    onBlur={getBirthDate}
                />
            </div>

            { isErrored ? errMsg : null }
        </div>
    );
};

BirthDate.defaultProps = {
    value: '2000-01-01',
};

export const BirthDateComponent = injectIntl(BirthDate);
