import { iframeConstants as constants } from '../../constants';
import Iframe from './iframe';
import { fadeInElements, removeElement } from './utils';

class Modal {
    verificationUrl: string;

    redirectOnMobile: boolean;

    overlay: HTMLElement;

    wrapper: HTMLElement;

    iframe: HTMLIFrameElement;

    isMobileDevice: boolean;

    closeButtonText: string;

    stopPropagation: boolean;

    constructor(url: string, userConfig: any) {
        const thresholdConfig = userConfig.mobileThreshold ? userConfig.mobileThreshold : constants.DEFAULT_MOBILE_THRESHOLD_WIDTH;
        this.verificationUrl = url;
        this.redirectOnMobile = userConfig.mobileRedirect || false;
        this.isMobileDevice = window.innerWidth <= thresholdConfig;
        this.closeButtonText = userConfig.closeButtonText ? userConfig.closeButtonText : '';
        this.stopPropagation = userConfig.stopPropagation || false;
        this.overlay = this.createOverlay();
        this.wrapper = this.createWrapper();
    }

    createCloseBtn() {
        const closeButton = document.createElement('a');
        closeButton.classList.add(constants.CLASS_NAMES.CLOSE_BUTTON);
        closeButton.href = '#';

        if (this.closeButtonText) {
            const closeTxt = document.createElement('div');
            closeTxt.classList.add(constants.CLASS_NAMES.CLOSE_TEXT);
            closeTxt.innerHTML = this.closeButtonText;
            closeButton.appendChild(closeTxt);
        }

        const closeIcon = document.createElement('span');
        closeIcon.classList.add(constants.CLASS_NAMES.CLOSE_ICON);
        closeIcon.setAttribute('role', 'img');
        closeIcon.setAttribute('aria-label', 'Close');
        closeButton.appendChild(closeIcon);

        closeButton.addEventListener('click', (e) => {
            e.preventDefault();
            if (this.stopPropagation) {
                e.stopPropagation();
            }
            this.closeModal();
        });
        return closeButton;
    }

    closeModal(historyEventTrigger: boolean = false) {
        window.removeEventListener('popstate', this.popStateEventHandler);
        const transitionTimeout = constants.MODAL_OPACITY_TRANSITION_PERIOD;
        removeElement(this.overlay, transitionTimeout);
        removeElement(this.wrapper, transitionTimeout);
        if (!historyEventTrigger) {
            window.history.back();
        }
    }

    // TODO remove eslint disable and fix
    // eslint-disable-next-line
    createOverlay() : HTMLElement {
        const overlay = document.createElement('div');
        overlay.classList.add(constants.CLASS_NAMES.OVERLAY);
        return overlay;
    }

    createWrapper() : HTMLElement {
        const wrapper = document.createElement('div');
        wrapper.classList.add(constants.CLASS_NAMES.MODAL_WRAPPER);
        wrapper.tabIndex = -1;
        wrapper.setAttribute('role', 'dialog');
        wrapper.appendChild(this.createCloseBtn());

        const iframe = new Iframe(wrapper, this.verificationUrl);
        const options = {
            className: constants.CLASS_NAMES.MODAL_IFRAME,
        };
        this.iframe = iframe.createIframe(options);
        wrapper.appendChild(this.iframe);
        return wrapper;
    }

    popStateEventHandler = () => this.closeModal(true);

    addPopStateListener() {
        window.addEventListener('popstate', this.popStateEventHandler);
    }

    displayModal() {
        this.iframe.addEventListener('load', () => {
            fadeInElements([this.overlay, this.wrapper]);
            document.body.style.overflow = 'hidden';
        });
        document.body.appendChild(this.overlay);
        document.body.appendChild(this.wrapper);
        window.history.pushState({}, document.title);
        this.addPopStateListener();
    }

    init() {
        if (this.redirectOnMobile && this.isMobileDevice) {
            window.top.location.href = this.verificationUrl;
        } else {
            this.displayModal();
        }
    }
}

export default Modal;
