import { Dispatch } from 'redux';
import { logger } from '../utils/logger/logger';
import {
    DatabaseId,
    VerificationResponse,
    ViewModel,
    BoundAction,
    BoundActionCreator,
    FieldValidationErrors,
    VerificationStep,
    ProgramTheme,
    Locale,
    Segment,
    ProgramThemeMessages,
    DocUploadViewModel,
    Organization,
    SetMessagesAction,
    ProgramThemeAction,
    VerificationResponseAction,
    ViewModelAction,
    IsLoadingAction,
    PreLoadOrgsAction,
    FieldValidationErrorsAction,
    PreviousViewModelAction,
    FormValidationOptionsAction,
    PersonalInfoViewModel,
    Country,
    WithCoreFields,
    StringMap,
} from '../types/types';
import { VerificationApiClient, getMockVerificationRequestErrorResponse } from '../ServerApi/VerificationApiClient';
import {
    handleSubmitResponse, initViewModel, clearUploadedFiles, collectFraudProfile, enhanceOrganizationFromTheme, determineCountry,
} from './VerificationServiceHelpers';
import { getHook, removeHook } from '../hooks/hooks';
import { getMessages, getLocaleSafely } from '../intl/intl';
import { getThemeMessages, getMetadataConfig, getConfiguredCountries } from '../ProgramTheme/programThemeGetters';
import { saveVerificationIdForConversion } from '../conversion/conversion';
import { scrollToWindowTop } from '../utils/browser/windowHelpers';
import { getFingerprint } from '../fingerprint/fingerprint';
import { deepClone } from '../utils/objects';
import { getMetadataFromUrl } from '../metadata/metadata';
import { fetchProgramOrganizations } from '../ServerApi/OrganizationApiClient';
import { SegmentEnum } from '../types/runtimeTypes';
import { getEmptyViewModel } from '../ServerApi/VerificationRequestGetters';
import { getFieldValidationErrorsEmpty, GetEmptyTheme } from '../types/empties';
import { closeTabRef } from '../refs/refs';
import { setGaDimensionIsTest } from '../GoogleAnalytics/ga';

/**
 * @description Start a new verification "flow"
 * @param dispatch Redux action dispatch
 */
export const createFetchNewVerificationRequest: BoundActionCreator = (
    dispatch: Dispatch,
): BoundAction => async (
    programId: DatabaseId,
    segment: Segment,
    previousViewModel?: ViewModel,
    trackingId?: string,
    forceNewVerificationRequest?: boolean,
) => {
    await dispatch<IsLoadingAction>({ type: 'IS_LOADING', isLoading: true });
    try {
        const verificationResponsePromise = VerificationApiClient.fetchNewVerificationRequest(programId, trackingId, forceNewVerificationRequest);
        return createFetchVerificationRequest({
            dispatch,
            verificationResponsePromise,
            programId,
            segment,
            previousViewModel,
        });
    } catch (e) {
        logger.error(e, 'fetchNewVerificationRequest');
    }
};

/**
 * @description Provided with a verificationId, continue verifying someone.
 * @param dispatch Redux action dispatch
 */
export const createFetchExistingVerificationRequest: BoundActionCreator = (
    dispatch: Dispatch,
): BoundAction => async (
    programId: DatabaseId,
    verificationId: string,
    previousVerificationResponse?: VerificationResponse,
    previousViewModel?: ViewModel,
    needsLoading: boolean = true,
) => {
    if (needsLoading) await dispatch<IsLoadingAction>({ type: 'IS_LOADING', isLoading: true });

    try {
        const verificationResponsePromise = VerificationApiClient.fetchExistingVerificationRequest(verificationId);
        return createFetchVerificationRequest({
            dispatch,
            verificationResponsePromise,
            programId,
            previousViewModel,
            previousVerificationResponse,
        });
    } catch (e) {
        logger.error(e, 'fetchExistingVerificationRequest');
    }
};

export const segmentChanged = (currentSegment: Segment, nextSegment: Segment): boolean => {
    if (!nextSegment) {
        return false;
    }
    return !!(currentSegment && currentSegment !== nextSegment);
};

export const currentStepChanged = (previousVerificationResponse: VerificationResponse, verificationResponse: VerificationResponse): boolean => !previousVerificationResponse || previousVerificationResponse.currentStep !== verificationResponse.currentStep;

/**
 * @description Await several items (new/existing verification request, program theme, etc) then update central state (redux).
 * Timing and order is very important within this function as it is bootstrapping most of the application.
 *
 * @param dispatch Redux action dispatch
 * @param verificationResponsePromise Either a new or existing verification server request.
 * @param programId
 * @param previousViewModel Optional
 * @private
 */
export const createFetchVerificationRequest = async ({
    dispatch,
    verificationResponsePromise,
    programId,
    segment,
    previousViewModel,
    previousVerificationResponse,
}: {
    dispatch: Dispatch;
    programId: DatabaseId;
    verificationResponsePromise: Promise<VerificationResponse>;
    segment?: Segment;
    previousViewModel?: ViewModel;
    previousVerificationResponse?: VerificationResponse;
}): Promise<any> => {
    try {
        const fingerprintPromise: Promise<string> = getFingerprint();
        const programThemePromise: Promise<ProgramTheme> = VerificationApiClient.fetchProgramTheme(programId);
        let verificationResponse: VerificationResponse;
        let programTheme: ProgramTheme;

        await Promise.all([
            verificationResponsePromise,
            programThemePromise,
        ])
            .then((responses) => {
                [verificationResponse, programTheme] = responses;
            })
            .catch((error) => {
                verificationResponse = getMockVerificationRequestErrorResponse(error.message);
                programTheme = GetEmptyTheme();
            });
        logger.info('verificationResponse received: ', verificationResponse);

        const locale = getLocaleSafely(previousViewModel, verificationResponse);
        const themeMessages: ProgramThemeMessages = getThemeMessages(programTheme);
        const messagesPromise: Promise<StringMap> = getMessages(locale, themeMessages, verificationResponse.segment);

        const nextSegment = verificationResponse.segment;

        let orgList: Organization[] = null;
        if (nextSegment === SegmentEnum.MILITARY) {
            const orgListPromise: Promise<Organization[]> = fetchProgramOrganizations(programTheme.config.orgSearchUrl, '');
            orgList = await orgListPromise;
            logger.info('orgList received: ', orgList);
        }

        clearUploadedFiles(previousViewModel as DocUploadViewModel); // Need to remove the file elements from state since deepClone cannot clone them

        let updatedPreviousViewModel = deepClone(previousViewModel);
        const previousSegment = previousVerificationResponse ? previousVerificationResponse.segment : segment;

        if (segmentChanged(previousSegment, nextSegment)) {
            logger.info('segment changed: ', previousSegment, nextSegment);
            await doViewModelReset(dispatch)(verificationResponse);
            updatedPreviousViewModel = undefined;
        }

        const fingerprint = await fingerprintPromise;
        const { currentStep } = verificationResponse;
        const countries: Country[] = getConfiguredCountries(programTheme);
        const country: Country = determineCountry(verificationResponse, countries);
        let viewModel = initViewModel({
            currentStep, fingerprint, locale, country, previousViewModel: updatedPreviousViewModel,
        });
        viewModel = enhanceOrganizationFromTheme(viewModel, programTheme);

        if (currentStepChanged(previousVerificationResponse, verificationResponse)) {
            getHook('ON_VERIFICATION_STEP_CHANGE')(verificationResponse);
            if (previousVerificationResponse
                && previousVerificationResponse.currentStep === 'sso'
                && verificationResponse.currentStep !== 'sso'
                && verificationResponse.currentStep !== 'pending'
            ) {
                // PROM-773 since we're no-longer on step sso (here in tab1), close the sso tab (tab2) we opened previously
                closeTabRef();
            }
        }

        if (programTheme.threatMetrixEnabled) {
            collectFraudProfile(verificationResponse.verificationId);
        }

        saveVerificationIdForConversion(verificationResponse.verificationId);

        if (viewModel && viewModel.hasOwnProperty('metadata')) {
            (viewModel as PersonalInfoViewModel).metadata = { ...(viewModel as PersonalInfoViewModel).metadata, ...getMetadataFromUrl(getMetadataConfig(programTheme).keys) };
        }

        const messages: StringMap = await messagesPromise;

        return Promise.all([
            dispatch<VerificationResponseAction>({ verificationResponse, type: 'VERIFICATION_RESPONSE' }),
            dispatch<ViewModelAction>({ viewModel, type: 'VIEW_MODEL' }),
            dispatch<ProgramThemeAction>({ programTheme, type: 'PROGRAM_THEME' }),
            dispatch<SetMessagesAction>({ messages, type: 'SET_MESSAGES' }),
            dispatch<IsLoadingAction>({ type: 'IS_LOADING', isLoading: false }),
            dispatch<PreLoadOrgsAction>({ orgList, type: 'PRE_LOAD_ORGS' }),
            dispatch<FormValidationOptionsAction>({ type: 'FORM_VALIDATION_OPTIONS' }),
        ])
            .then(() => {
                getHook('ON_VERIFICATION_READY')(verificationResponse);
                // This hook is a special case
                // Do not call this hook again after the page has initially loaded, such as pending step's polling behavior
                removeHook('ON_VERIFICATION_READY');
            });
    } catch (e) {
        logger.error(e, 'createFetchVerificationRequest');
        throw (e);
    }
};

/**
 * @description Action creator to update the view model in redux.
 * @param dispatch
 */
export const createUpdateViewModel: BoundActionCreator = (
    dispatch: Dispatch,
): BoundAction => async (
    viewModel: ViewModel,
// TODO fix next line
// eslint-disable-next-line
) => await Promise.all([
    dispatch<ViewModelAction>({ viewModel, type: 'VIEW_MODEL' }),
    dispatch<FormValidationOptionsAction>({ type: 'FORM_VALIDATION_OPTIONS' }),
]);

type CreateUpdateProps = (dispatch: Dispatch, programId: DatabaseId) => BoundAction;
export const createUpdateLocale: CreateUpdateProps = (
    dispatch: Dispatch,
    programId,
): BoundAction => async (
    viewModel: ViewModel,
    programTheme: ProgramTheme,
    segment: Segment,
): Promise<object> => {
    dispatch<IsLoadingAction>({ type: 'IS_LOADING', isLoading: true });
    const locale: Locale = getLocaleSafely(viewModel);
    const newProgramTheme: ProgramTheme = await VerificationApiClient.fetchProgramTheme(programId, locale);
    const themeMessages: ProgramThemeMessages = getThemeMessages(newProgramTheme);
    return getMessages(locale, themeMessages, segment)
        .then((messages: StringMap) => Promise.all([
            dispatch<SetMessagesAction>({ messages, type: 'SET_MESSAGES' }),
            dispatch<ViewModelAction>({ viewModel, type: 'VIEW_MODEL' }),
            dispatch<IsLoadingAction>({ type: 'IS_LOADING', isLoading: false }),
        ]));
};

/**
 * @description Action creator to update the program theme in redux.
 * @param dispatch
 */
export const createUpdateProgramTheme: BoundActionCreator = (
    dispatch: Dispatch,
): BoundAction => async (
    programTheme: ProgramTheme,
) => dispatch<ProgramThemeAction>({ programTheme, type: 'PROGRAM_THEME' });

/**
 * @description Action creator to update the field validation errors, which are those that are shown to a user as they fill-in the HTML form.
 * @param dispatch
 */
export const createUpdateFieldValidationErrors: BoundActionCreator = (
    dispatch: Dispatch,
): BoundAction => async (
    fieldValidationErrors: FieldValidationErrors,
) => dispatch<FieldValidationErrorsAction>({ fieldValidationErrors, type: 'FIELD_VALIDATION_ERRORS' });

/**
 * @description Action creator to submit a step to the backend REST API
 * @param dispatch
 */
export const createSubmitStep: BoundActionCreator = (
    dispatch: Dispatch,
): BoundAction => async (stepName: VerificationStep, viewModel: ViewModel, previousResponse: VerificationResponse) => {
    logger.log(`submitStep(): submitting stepName ${stepName}, viewModel:`, viewModel);
    scrollToWindowTop();
    await dispatch<IsLoadingAction>({ type: 'IS_LOADING', isLoading: true });
    logger.info(`submitting step ${stepName}`, viewModel);

    setGaDimensionIsTest(viewModel as WithCoreFields);

    const verificationResponse: VerificationResponse = await VerificationApiClient.submitStep(stepName, previousResponse, viewModel);
    const newViewModel = handleSubmitResponse(verificationResponse, viewModel);

    const promiseVerificationResponse = dispatch<VerificationResponseAction>({ verificationResponse, type: 'VERIFICATION_RESPONSE' });
    const promiseViewModel = dispatch<ViewModelAction>({ viewModel: newViewModel, type: 'VIEW_MODEL' });
    const promiseIsLoading = dispatch<IsLoadingAction>({ type: 'IS_LOADING', isLoading: false });

    getHook('ON_VERIFICATION_STEP_CHANGE')(verificationResponse);

    return Promise.all([promiseVerificationResponse, promiseViewModel, promiseIsLoading]);
};

/**
 * Perform the actual viewModel reset actions
 * @private
 */
export const doViewModelReset = (dispatch: Dispatch) => (verificationResponse: VerificationResponse): Promise<any> => Promise.all([
    dispatch<ViewModelAction>({ type: 'VIEW_MODEL', viewModel: getEmptyViewModel(verificationResponse.currentStep) }),
    dispatch<PreviousViewModelAction>({ type: 'PREVIOUS_VIEW_MODEL', previousViewModel: undefined }),
    dispatch<FieldValidationErrorsAction>({ type: 'FIELD_VALIDATION_ERRORS', fieldValidationErrors: getFieldValidationErrorsEmpty() }),
]);
