import { Metadata } from '../types/types';
import { setOptions } from '../../options/options';
import { validateMetadata, ensureMaxMetadataKeyValueLengths } from '../validators/validators';
import { getQueryParamsFromUrl } from '../utils/routing/Url';
import { logger } from '../utils/logger/logger';
import { deepClone } from '../utils/objects';

const defaultMetadata: Metadata = {};
let metadata: Metadata = Object.assign({}, defaultMetadata);

export const getMetadataFromUrl = (paramsFromResponse: string[]) => {
    const paramsFromQuery = getQueryParamsFromUrl();
    const validatedParams = {};
    paramsFromResponse.forEach((element) => {
        if (paramsFromQuery.get(element)) {
            validatedParams[element] = paramsFromQuery.get(element);
        }
    });
    return validatedParams;
};

export const setMetadata = (newMetadata: Metadata) => {
    // backwards opt-in metadata key. It is supported now as an Option
    if (newMetadata && newMetadata.hasOwnProperty('optin')) {
        setOptions({
            marketConsent: {
                /* keep metadata optin compatibility as string value. Set true any string but false */
                enabled: newMetadata.optin !== 'false',
                required: false,
                message: '',
            },
        });
    }

    const isMetadataValid: boolean = validateMetadata(newMetadata);

    if (isMetadataValid) {
        const modifiedMetadata = ensureMaxMetadataKeyValueLengths(newMetadata);
        Object.assign(metadata, modifiedMetadata);
        logger.info('Added metadata: ', modifiedMetadata);
        logger.info('Full metadata: ', metadata);
    } else {
        logger.error('Metadata has not been updated. See previous error(s)');
    }
};

export const resetMetadata = () => {
    metadata = Object.assign({}, defaultMetadata);
};

export const getMetadata = () => (Object.keys(metadata).length > 0 ? deepClone(metadata) : undefined);

