import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { VerificationService, ProgramTheme } from '../../lib/types/types';
import { getSafe } from '../../lib/utils/objects';

export const RewardsRemainingComponent = ({ verificationService }: {verificationService: VerificationService}) => {
    const offersRemaining: ProgramTheme['remainingRewardCodes'] = getSafe(() => verificationService.programTheme.remainingRewardCodes, null);

    if (typeof offersRemaining === 'number' && offersRemaining > 0) {
        return (
            <div className="sid-rewards-remaining">
                <div className="sid-l-space-top-md" />
                <FormattedHTMLMessage
                    id="lowRewardPool"
                    defaultMessage={verificationService.messages.lowRewardPool}
                    values={{ offersRemaining }}
                />
                <div className="sid-l-space-top-sm" />
            </div>
        );
    }

    return null;
};
