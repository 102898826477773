import React from 'react';

interface InputSelectButtonProps {
    clearSelection: any;
    disabled?: boolean;
    getToggleButtonProps: any;
    isOpen: boolean;
}

const InputSelectButton = ({
    clearSelection, disabled, getToggleButtonProps, isOpen,
}: InputSelectButtonProps) => {
    if (clearSelection) {
        return (
            <button
                aria-label="clear selection"
                className="sid-input-select-button"
                disabled={disabled}
                onClick={clearSelection}
                type="button"
            >
                <svg
                    viewBox="0 0 20 20"
                    preserveAspectRatio="none"
                    width={12}
                    fill="transparent"
                    stroke="#979797"
                    strokeWidth="1.1px"
                >
                    <path d="M1,1 L19,19" />
                    <path d="M19,1 L1,19" />
                </svg>
            </button>
        );
    }

    return (
        <button
            {...getToggleButtonProps()}
            className="sid-input-select-button"
            disabled={disabled}
            type="button"
        >
            <svg
                viewBox="0 0 20 20"
                preserveAspectRatio="none"
                width={16}
                fill="transparent"
                stroke="#979797"
                strokeWidth="1.1px"
                transform={isOpen ? 'rotate(180)' : undefined}
            >
                <path d="M1,6 L10,15 L19,6" />
            </svg>
        </button>
    );
};

export const InputSelectButtonComponent = InputSelectButton;
