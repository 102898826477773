import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { InputTextComponent } from '../InputText/InputText';

interface PostalCodeInputProps extends InjectedIntlProps {
    onChange: Function;
    value: any;
    isValid?: boolean;
    placeholder?: string;
}

const PostalCodeInput = ({
    onChange,
    value,
    isValid,
    intl,
    placeholder = '',
}: PostalCodeInputProps) => (
    <InputTextComponent
        id="postal-code"
        isErrored={!isValid}
        onChange={e => onChange(e.target.value)}
        pattern="[a-zA-Z0-9 ]*"
        placeholder={placeholder || intl.formatMessage({ id: 'postalCodePlaceholder', defaultMessage: 'Postal Code*' })}
        refId="postalCode"
        value={value}
        required
    />
);

PostalCodeInput.defaultProps = {
    isValid: true,
};

export const PostalCodeInputComponent = injectIntl(PostalCodeInput);
