import { Locale } from './lib/types/types';

export const DEFAULT_LOCALE: Locale = 'en-US';
export const DEFAULT_CDN_BASE_URL: string = 'https://cdn.jsdelivr.net/npm/@sheerid/jslib@1/';

export const ACCEPTED_DOC_MIME_TYPES: string[] = [
    'image/png',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'application/pdf',
];

export const MAX_DOC_UPLOAD_DOCS_ALLOWED: number = 3;
export const UPLOAD_FILE_PREFIX: string = 'file';

export const QUERY_STRING_STEP_OVERRIDE: string = 'mockStep';
export const QUERY_STRING_SUBSEGMENT_OVERRIDE: string = 'mockSubSegment';
export const QUERY_STRING_ERRORID_OVERRIDE: string = 'mockErrorId';
export const QUERY_STRING_PREV_STEP_OVERRIDE: string = 'mockPreviousStep';
export const QUERY_STRING_INSTALL_PAGE_URL: string = 'installPageUrl';

export const SSN_STRING_LENGTH: number = 9;
export const DEFAULT_MINIMUM_ORG_SEARCH_VALUE_LENGTH: number = 1;

export const DEFAULT_PRIVACY_POLICY_HOST = 'www.sheerid.com';
export const DEFAULT_PRIVACY_POLICY_URL = 'https://www.sheerid.com/privacy-policy/';
export const SHEERID = 'SheerID';

export const requestOrganizationConstants = {
    MAX_RESULT_SIZE: 25,
    ORG_TYPES: 'UNIVERSITY,HIGH_SCHOOL,K12',
    DEFAULT_ORG_TYPES: 'UNIVERSITY',
    URL_REGEX: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    SCHOOL_HOUSE_URL: 'https://s3.amazonaws.com/com.sheerid.resources/common/images/requestOrganization/school.svg',
    SHEERID_LOGO_URL: 'https://s3.amazonaws.com/com.sheerid.resources/common/images/requestOrganization/logo.svg',
    FEEDBACK_FORM_URL: 'https://verify.sheerid.com/verification-support/feedback.html?token=',
};

export const HTTP_REQUEST_TIMEOUT = 10000;

const iframeClassNames = {
    INLINE_IFRAME_CONTENT: 'sid-inline-iframe',
    MODAL_WRAPPER: 'sid-modal__wrapper',
    MODAL_IFRAME: 'sid-modal__iframe',
    OVERLAY: 'sid-modal__overlay',
    CLOSE_BUTTON: 'sid-modal__close-button',
    CLOSE_TEXT: 'sid-modal__close-text',
    CLOSE_ICON: 'sid-modal__close-icon',
};

export const iframeConstants = {
    CLASS_NAMES: iframeClassNames,
    DEFAULT_MOBILE_THRESHOLD_WIDTH: 620,
    MODAL_OPACITY_TRANSITION_PERIOD: 300,
};
