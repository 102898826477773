/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from '../../types/types';

export const studentMessages: SegmentSpecificMessages = {
    emailExplanation: 'Needed to send you your unique code',
    schoolName: 'University / college name',
    step: {
        personalInfo: {
            title: 'Unlock this Student-Only Offer',
            subtitle: "Verify you're a student at a degree-granting school.",
            howDoesVerifyingWorkDetails: `
                To be eligible for this offer, you must currently be enrolled in a degree
                or certificate-granting, accredited school of education.
                <br><br>
                {companyName} uses SheerID, a trusted partner, to verify that you are a current student. SheerID only
                collects information that assists in verifying your eligibility.
                Additional information or documentation may be requested.`,
            tryAgain: {
                title: 'Check Your Name & Birthdate',
                subtitle: 'Your full name and birthdate must match what is on your official school records.',
            },
        },
        docUpload: {
            title: 'We need your ID & class schedule',
            subtitle: 'Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.',
            howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying students.
                <br/><br/>
                Documents are used only for verifying your student status is current and will be permanently deleted after review is finalized. They will not be shared with {companyName}`,
            uploadInstructions: 'Upload your school-issued document that shows',
        },
        success: {
            title: "You've been verified",
            subtitle: 'Here is your personal coupon code. It is unique to you and can only be used once.',
            redirectButtonText: 'Use code now',
        },
        sso: {
            cancel: 'Upload Proof of Enrollment',
        },
    },
};
