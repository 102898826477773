/**
 * TODO - preamble
 */
import {
    ViewModel,
    VerificationStep,
} from '../types/types';

import {
    getAllEmptyViewModels,
} from '../types/empties';

import { assertValidVerificationStepName } from '../types/assertions';
import { logger } from '../utils/logger/logger';

/**
 * Return an empty request object as a starting-point for our run-time model
 */
export const getEmptyViewModel = (currentStep: VerificationStep): ViewModel => {
    assertValidVerificationStepName(currentStep);
    const emptyViewModel = getAllEmptyViewModels()[currentStep];
    if (!emptyViewModel) {
        logger.log(`Can't find "empty" for ${currentStep}`);
        throw new Error('Check for missing empties');
    }
    return emptyViewModel;
};
