/* eslint-disable */
// TODO remove eslint-disable and fix this file
import React from 'react';
import { Dispatch } from 'redux';
import {
    connect, MapStateToProps, MapDispatchToProps, MergeProps,
} from 'react-redux';

import {
    DatabaseId,
    Locale,
    ReduxState,
    VerificationService,
    VerificationServiceFunctions,
    VerificationServiceValues,
    Segment,
    StringMap,
} from '../lib/types/types';

import { logger } from '../lib/utils/logger/logger';
import { isMockingLoading } from '../lib/ServerApi/TestingRouteOverrides';
import { LoadingScreenComponent as LoadingScreen } from './LoadingScreen/LoadingScreenComponent';
import {
    createFetchNewVerificationRequest,
    createUpdateViewModel,
    createUpdateFieldValidationErrors,
    createSubmitStep,
    createFetchExistingVerificationRequest,
    createUpdateProgramTheme,
    createUpdateLocale,
} from '../lib/VerificationService/VerificationServiceActions';

import { getLocaleSafely } from '../lib/intl/intl';
import { getSafe } from '../lib/utils/objects';
import { NewVerificationInnerComponent } from './NewVerificationInnerComponent';
import { enableES5 } from 'immer';

interface NewVerificationProps {
    programId: DatabaseId;
    messages?: StringMap;
    verificationService?: VerificationService;
    trackingId?: string;
}
interface NewVerificationState {}

export class NewVerification extends React.Component<NewVerificationProps, NewVerificationState> {
    verificationService: VerificationService;

    constructor(props) {
        super(props);
        logger.info('NewVerificationComponent: constructing');

        if (this.props.trackingId && this.props.trackingId.length) {
            logger.info(`Attaching a trackingId to this verification request ${this.props.trackingId}`);
        } else {
            logger.info('No trackingId to associate with the verification request');
        }
    }

    componentDidMount() {
        enableES5(); // https://sheerid.atlassian.net/browse/UX-362

        // Special-case, mocking the loading psuedo-step
        if (isMockingLoading()) {
            logger.info('Mocking loading screen. Not fetching new verification request.');
        } else {
            logger.info('NewVerificationComponent: fetchNewVerificationRequest');
            const currentSegment = getSafe<Segment>(() => this.props.verificationService.verificationResponse.segment);
            this.props.verificationService.fetchNewVerificationRequest(
                this.props.programId,
                currentSegment,
                this.props.verificationService.previousViewModel,
                this.props.trackingId,
            );
        }
    }

    public render() {
        if (this.props.verificationService.isLoading) {
            logger.info('NewVerificationComponent: VerificationService is loading');
            return <LoadingScreen />; // Note: we don't know locale yet
        }

        const { programTheme } = this.props.verificationService;
        const { verificationResponse } = this.props.verificationService;
        const { viewModel } = this.props.verificationService;
        logger.info('NewVerificationComponent: verificationResponse, viewModel', verificationResponse, viewModel);

        const locale: Locale = getLocaleSafely(viewModel);

        let messages: StringMap;
        // optional param `messages`. If we do not have it, all <FormattedMessages /> should use default fallback anyway.
        if (this.props.messages) {
            messages = this.props.messages;
        }

        logger.info(`NewVerificationComponent: locale: ${locale}, currentStep: ${verificationResponse.currentStep}, messages: `, messages);

        return (
            <NewVerificationInnerComponent
                locale={locale}
                messages={messages}
                programTheme={programTheme}
                verificationService={this.props.verificationService}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxState, NewVerificationProps, any> = (state: ReduxState, ownProps: NewVerificationProps): ReduxState => ({
    ...state,
});

const mapDispatchToProps: MapDispatchToProps<any, any> = (dispatch: Dispatch, ownProps: NewVerificationProps) => ({
    fetchNewVerificationRequest: createFetchNewVerificationRequest(dispatch),
    fetchExistingVerificationRequest: createFetchExistingVerificationRequest(dispatch),
    updateViewModel: createUpdateViewModel(dispatch),
    updateLocale: createUpdateLocale(dispatch, ownProps.programId as DatabaseId),
    updateProgramTheme: createUpdateProgramTheme(dispatch),
    updateFieldValidationErrors: createUpdateFieldValidationErrors(dispatch),
    submitStep: createSubmitStep(dispatch),
});

// Return a VerificationService
const mergeProps: MergeProps<VerificationServiceValues & ReduxState, VerificationServiceFunctions, NewVerificationProps, any> = (
    propsFromState,
    propsFromDispatch,
    ownProps,
): NewVerificationProps => ({
    verificationService: {
        ...propsFromState,
        ...propsFromDispatch,
    },
    messages: propsFromState.messages,
    ...ownProps,
});

export const NewVerificationComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(NewVerification);
