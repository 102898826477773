import { logger } from '../utils/logger/logger';
import { Validator, ExtendedFieldId } from '../types/types';

let customValidators = {};
let customValidatorFields: ExtendedFieldId[] = [];

export const setCustomValidator = (validatorField: ExtendedFieldId, newValidator: Validator) => {
    logger.log(`customValidator registering \n${newValidator}\n for custom field '${validatorField}'`);
    customValidators[validatorField] = newValidator;
    customValidatorFields.push(validatorField);
};

export const getCustomValidator = (validatorField: ExtendedFieldId): Validator => {
    if (customValidators[validatorField]) {
        logger.log(`getCustomValidator returning '${validatorField}'`);
        return customValidators[validatorField];
    }
    logger.error(`Custom validator for field '${validatorField}' does not exist.
        Has a custom validator been registered using the setCustomValidator method?`, 'getCustomValidator');
    return null;
};

export const customValidatorExists = (validatorField: ExtendedFieldId): boolean => customValidatorFields.indexOf(validatorField) > -1;

export const removeCustomValidator = (validatorField: ExtendedFieldId) => {
    delete customValidators[validatorField];

    const validatorIndex = customValidatorFields.indexOf(validatorField);

    if (validatorIndex !== -1) {
        customValidatorFields.splice(validatorIndex, 1);
    } else {
        logger.error(`Custom validator for field '${validatorField}' has not been removed.`, 'removeCustomValidator');
    }
};

export const getCustomValidatorFields = (): ExtendedFieldId[] => customValidatorFields;

export const resetCustomValidators = () => {
    customValidators = {};
    customValidatorFields = [];
    logger.info('Custom validators have been reset');
};
