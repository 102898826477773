/**
 * Google Analytics custom event recording
 */
import {
    VerificationStep, ReduxState, DocUploadViewModel, VerificationResponse, WithCoreFields,
} from '../types/types';
import { logger } from '../utils/logger/logger';
import { VerificationStepsEnum } from '../types/runtimeTypes';

const events = {
    EVENT_CATEGORY: 'Verification',
};

export enum isTestValues {
    TRUE = 'true',
    FALSE = 'false',
}

export const isTestEmailDomains = [
    'sheerid.com',
    'wizeline.com',
];

// GA admin config needs to correlate with these dimension index numbers:
const dimensions = {
    verificationId: 'dimension1',
    programId: 'dimension2',
    jslibVersionActual: 'dimension3',
    isTest: 'dimension4',
};

export const recordEvent = (step: VerificationStep, label: string = '') => {
    window.ga('send', 'event', events.EVENT_CATEGORY, step, label);
};

export const recordViewModelChange = (oldState: ReduxState, newState: ReduxState) => {
    try {
        if (newState.verificationResponse.currentStep === VerificationStepsEnum.docUpload) {
            if ((oldState.viewModel as DocUploadViewModel).file1 === undefined && (newState.viewModel as DocUploadViewModel).file1 !== undefined) {
                recordEvent(VerificationStepsEnum.docUpload, 'addFiles');
            }
        }
    } catch (e) {
        logger.warn('Failed addFiles event', e);
    }
};

export const recordVerificationResponse = (verificationResponse: VerificationResponse) => {
    try {
        const label = verificationResponse.errorIds && Array.isArray(verificationResponse.errorIds)
            ? verificationResponse.errorIds.sort().join(',') : undefined;
        recordEvent(verificationResponse.currentStep, label);
    } catch (e) {
        logger.warn('Failed recordVerificationResponse event', e);
    }
};

export const setDimension = (dimensionName: keyof typeof dimensions, value: string) => {
    try {
        window.ga('set', dimensions[dimensionName], value);
    } catch (e) {
        logger.warn(`Failed to set ${dimensionName}`, e);
    }
};


export const setGaDimensionIsTest = (viewModel: WithCoreFields) => {
    try {
        const { email } = viewModel;
        if (email && isTestEmailDomains.filter(domain => email.indexOf(domain) > -1).length > 0) {
            setDimension('isTest', isTestValues.TRUE); // Note that this may override previously-sent `programTheme.isTestMode`
        }
    } catch (e) {
        logger.warn('Error setting isTest dimension', e);
    }
};
