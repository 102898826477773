import { ProgramTheme } from '../types/types';
import { getSafe } from '../utils/objects';

const loadIPQSDeviceFingerprintScript = async (): Promise<void> => {
    const existingScript = document.getElementById('ipqs');

    if (!existingScript) {
        return new Promise<void>(resolve => {
            const scriptTag = document.createElement('script');
            scriptTag.src = 'https://fd.sheerid.com/api/*/BJOvvIiNpZnA9XHXIHVc0S4FO87k4eub6NLOfmShTU7nRqamLKTzQixwD7XETz7bvtNHmicHNx9hEtOJ9NPo3kUJBl7o1jpwcbcXeOMDJjvulAWSrRnO7WYq9gxL6xNT0xnfou5UlshUGWQ2g68qBuWajMWbxZ25JELntxaP0neiVUbephG5E79ES89qBo4uIGBDvykdJb75hpo0URvJ0Fm1j6fuEqHQBq64Mi390KC9XoQwiFxyboxQ5lSooY4p/learn.js';
            scriptTag.async = true;
            scriptTag.crossOrigin = 'anonymous';
            scriptTag.id = 'ipqs';
            document.body.appendChild(scriptTag);

            scriptTag.onload = () => {
                resolve();
            };
        });
    }
};

export const includeIPQSDeviceFingerprintScript = async (programTheme: ProgramTheme, verificationId: string): Promise<void> => {
    if (getSafe(() => programTheme.ipqsDeviceFingerprintEnabled, false)) {
        await loadIPQSDeviceFingerprintScript();

        window.IPQ = {
            Callback: () => {
                window.Startup.Store('transactionID', verificationId);
                window.Startup.Init();
            },
        };
    }
};
