import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import {
    FormFieldComponentProps, ExtendedErrorId, ExtendedFieldId, FieldContent, Validator, MarketConsentOptions,
} from '../../../lib/types/types';
import { OptInInputComponent } from '../../FormInputs/OptInInput/OptInInputComponent';
import { setCustomValidator } from '../../../lib/validators/customValidators';
import { getMarketConsent } from '../../../lib/ProgramTheme/programThemeGetters';

const OptInComponent = ({
    isErrored,
    isRequired,
    onChange,
    value,
    verificationService,
}: FormFieldComponentProps) => {
    const optInFieldId: ExtendedFieldId = 'marketConsentValue';

    const getOptInValidationError: Validator = React.useCallback((value: FieldContent): ExtendedErrorId => {
        const invalidError: ExtendedErrorId = 'marketConsentRequired';
        if (isRequired && !value) {
            return invalidError;
        }
    }, [isRequired]);

    const marketConsentConfig = (verificationService
        && verificationService.programTheme && getMarketConsent(verificationService.programTheme)) || {} as MarketConsentOptions;
    const defaultMessage = marketConsentConfig.message
        || 'I would like to receive marketing communications, including email, from {company} and its family of brands.';


    React.useEffect(() => {
        setCustomValidator(optInFieldId, getOptInValidationError);
    }, [optInFieldId, getOptInValidationError]);

    return (
        <div className="sid-field sid-opt-in">
            <div className="sid-l-space-top-md" />
            <div className="sid-l-position">
                <label htmlFor="sid-opt-in">
                    <OptInInputComponent
                        isErrored={isErrored}
                        onChange={onChange}
                        value={value}
                    />
                    <div className="sid-field__label sid-checkbox__label">
                        { verificationService
                            ? (
                                <FormattedMessage id="companyName" defaultMessage="{Company}">
                                    {companyName => (
                                        <FormattedHTMLMessage
                                            id="optIn"
                                            values={{ company: companyName }}
                                            defaultMessage={defaultMessage}
                                        />
                                    )}
                                </FormattedMessage>
                            )
                            : (
                                <FormattedHTMLMessage
                                    id="optIn"
                                    defaultMessage="I would like to receive marketing communications, including email, from {company} and its family of brands."
                                />
                            )}
                    </div>
                    { isErrored
                        ? (
                            <div className="sid-field-error">
                                <FormattedHTMLMessage
                                    id="errorId.invalidOptIn"
                                    defaultMessage="Must be selected to continue"
                                />
                            </div>
                        )
                        : null}
                </label>
            </div>
        </div>
    );
};

export { OptInComponent };
