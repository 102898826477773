import { messages as enUSMessages } from '../lib/intl/messages/en-US';
import {
    DocUploadResponse,
    ErrorResponse,
    NewVerificationRequest,
    StudentPersonalInfoResponse,
    TeacherPersonalInfoResponse,
    MemberPersonalInfoResponse,
    PendingResponse,
    StudentPersonalInfoViewModel,
    TeacherPersonalInfoViewModel,
    MemberPersonalInfoViewModel,
    SuccessResponse,
    DocUploadViewModel,
    ActiveMilitaryPersonalInfoViewModel,
    ActiveMilitaryPersonalInfoResponse,
    InactiveMilitaryPersonalInfoResponse,
    InactiveMilitaryPersonalInfoViewModel,
    SeniorPersonalInfoResponse,
    SeniorPersonalInfoViewModel,
    MilitaryStatusResponse,
    SSOResponse,
    SMSLoopResponse,
    FirstResponderPersonalInfoResponse,
    FirstResponderPersonalInfoViewModel,
    EmploymentPersonalInfoResponse,
    EmploymentPersonalInfoViewModel,
    ProgramTheme,
    RequestOrganizationService,
    SocialSecurityResponse,
    SocialSecurityViewModel,
    AgePersonalInfoResponse,
    MedicalProfessionalPersonalInfoResponse,
    VerificationStepMap,
    AllResponseTypes,
    EmailLoopResponse,
    DriverLicensePersonalInfoResponse,
    GeneralIdentityPersonalInfoResponse,
    HybridIdentityPersonalInfoResponse,
    LicensedProfessionalPersonalInfoViewModel,
    LicensedProfessionalPersonalInfoResponse,
    MoverPersonalInfoViewModel,
    MoverPersonalInfoResponse,

} from '../lib/types/types';
import { employmentPInfoReqEmpty, socialSecurityEmpty } from '../lib/types/empties';

// ===================== New Verification ====================
export const mockNewRequest: NewVerificationRequest = {
    programId: '5bbd127d9781852f68e14ddc',
};

// ===================== Student Personal Info ====================

export const mockStudentPersonalInfoResponse: StudentPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectStudentPersonalInfo',
    segment: 'student',
    subSegment: null,
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// Continue the verification process for a given verificationId
export const mockStudentPersonalInfoViewModel: StudentPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '', // ISO-8601 'YYYY-MM-DD'
    organization: {
        id: 0,
        name: '',
    },
    countryChoice: undefined,
    localeChoice: {
        value: undefined,
        label: undefined,
    },
};

// ===================== Teacher Personal Info ====================

export const mockTeacherPersonalInfoResponse: TeacherPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectTeacherPersonalInfo',
    segment: 'teacher',
    subSegment: null,
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// Continue the verification process for a given verificationId
export const mockTeacherPersonalInfoViewModel: TeacherPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    organization: {
        id: 0,
        name: '',
    },
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== Member Personal Info ====================

export const mockMemberPersonalInfoResponse: MemberPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectMemberPersonalInfo',
    segment: 'member',
    subSegment: null,
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};
export const mockMemberPersonalInfoViewModel: MemberPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    phoneNumber: '',
    organization: {
        id: 0,
        name: '',
    },
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== Senior Personal Info ====================

export const mockSeniorPersonalInfoResponse: SeniorPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectSeniorPersonalInfo',
    segment: 'senior',
    subSegment: null,
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// ===================== Age Personal Info ====================

export const mockAgePersonalInfoResponse: AgePersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectAgePersonalInfo',
    segment: 'age',
    subSegment: null,
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
    minAge: 55,
    maxAge: 120,
};

// Continue the verification process for a given verificationId
export const mockSeniorPersonalInfoViewModel: SeniorPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '',
    postalCode: '',
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== Military Status ====================
export const mockMilitaryStatusResponse: MilitaryStatusResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectMilitaryStatus',
    segment: 'military',
    subSegment: null,
    submissionUrl: '',
    availableStatuses: ['ACTIVE_DUTY'],
    locale: 'en-US',
};

// ===================== Active Military Personal Info ====================

export const mockActiveMilitaryPersonalInfoResponse: ActiveMilitaryPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectActiveMilitaryPersonalInfo',
    segment: 'military',
    subSegment: 'activeDuty',
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// Continue the verification process for a given verificationId
export const mockActiveMilitaryPersonalInfoViewModel: ActiveMilitaryPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '', // ISO-8601 'YYYY-MM-DD'
    status: undefined,
    organization: {
        id: 0,
        name: '',
    },
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== Inactive Military Personal Info ====================

export const mockInactiveMilitaryPersonalInfoResponse: InactiveMilitaryPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectInactiveMilitaryPersonalInfo',
    segment: 'military',
    subSegment: 'veteran',
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// Continue the verification process for a given verificationId
export const mockInactiveMilitaryPersonalInfoViewModel: InactiveMilitaryPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '', // ISO-8601 'YYYY-MM-DD'
    status: undefined,
    dischargeDate: '', // ISO-8601 'YYYY-MM-DD'
    organization: {
        id: 0,
        name: '',
    },
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== First Responder Personal Info ====================

export const mockFirstResponderPersonalInfoResponse: FirstResponderPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectFirstResponderPersonalInfo',
    segment: 'firstResponder',
    subSegment: 'emt',
    submissionUrl: '',
    availableStatuses: ['FIREFIGHTER'],
    instantMatchAttempts: 0,
    locale: 'en-US',
};

export const mockMedicalProfessionalPersonalInfoResponse: MedicalProfessionalPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectMedicalProfessionalPersonalInfo',
    segment: 'medical',
    subSegment: 'nurse',
    submissionUrl: '',
    availableStatuses: ['NURSE'],
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// Continue the verification process for a given verificationId
export const mockFirstResponderPersonalInfoViewModel: FirstResponderPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '', // ISO-8601 'YYYY-MM-DD'
    status: undefined,
    organization: {
        id: 0,
        name: '',
    },
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== Employment Personal Info ====================

export const mockEmploymentPersonalInfoResponse: EmploymentPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectEmployeePersonalInfo',
    segment: 'employment',
    subSegment: null,
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// Continue the verification process for a given verificationId
export const mockEmploymentPersonalInfoViewModel: EmploymentPersonalInfoViewModel = employmentPInfoReqEmpty;

// ===================== Identity Personal Info =========================

export const mockDriverLicensePersonalInfoResponse: DriverLicensePersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectDriverLicensePersonalInfo',
    segment: 'identity',
    subSegment: 'driverLicense',
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

export const mockGeneralIdentityPersonalInfoResponse: GeneralIdentityPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectGeneralIdentityPersonalInfo',
    segment: 'identity',
    subSegment: 'generalIdentity',
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

export const mockHybridIdentityPersonalInfoResponse: HybridIdentityPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectHybridIdentityPersonalInfo',
    segment: 'identity',
    subSegment: 'generalIdentity',
    submissionUrl: '',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

// ===================== Professionals Personal Info =========================

export const mockLicensedProfessionalPersonalInfoResponse: LicensedProfessionalPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9761',
    currentStep: 'collectLicensedProfessionalPersonalInfo',
    segment: 'licensedProfessional',
    submissionUrl: '',
    subSegment: 'licensedCosmetologists',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

export const mockLicensedProfessionalPersonalInfoViewModel: LicensedProfessionalPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    birthDate: '',
    postalCode: '',
    email: '',
    statuses: ['LICENSED_COSMETOLOGIST'],
    organization: {
        id: 0,
        name: '',
    },
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== Movers Personal Info =========================

export const mockMoverPersonalInfoResponse: MoverPersonalInfoResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9761',
    currentStep: 'collectMoverPersonalInfo',
    segment: 'recentMover',
    submissionUrl: '',
    subSegment: 'homeBuyer',
    instantMatchAttempts: 0,
    locale: 'en-US',
};

export const mockMoverPersonalInfoViewModel: MoverPersonalInfoViewModel = {
    firstName: '',
    lastName: '',
    address1: '',
    postalCode: '',
    email: '',
    statuses: ['HOME_BUYER', 'OTHER_MOVER'],
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};

// ===================== Social Security Number step ====================
export const mockSocialSecurityResponse: SocialSecurityResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'collectSocialSecurityNumber',
    segment: 'military',
    subSegment: null,
    submissionUrl: '',
    cancelUrl: '',
    locale: 'en-US',
};

export const mockSocialSecurityViewModel: SocialSecurityViewModel = socialSecurityEmpty;

// ======================= Step Doc Upload =====================

export const mockDocUploadVerificationResponse: DocUploadResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'docUpload',
    segment: 'student',
    subSegment: null,
    submissionUrl: '',
    locale: 'en-US',
};

// Continue the verification process for a given verificationId
export const mockDocUploadViewModel: DocUploadViewModel = {
    file1: null,
    file2: null,
    localeChoice: {
        value: undefined,
        label: undefined,
    },
    countryChoice: undefined,
};


// ========================= "Step" Success =======================

export const mockSuccessResponse: SuccessResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'success',
    segment: 'student',
    subSegment: null,
    rewardCode: 'MY_REWARD_123',
    locale: 'en-US',
};
// There is no request associated with this step

// ============================ "Step" Error ===========================

export const mockErrorResponse: ErrorResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'error',
    segment: 'student',
    subSegment: null,
    redirectUrl: 'https://sheerid.com',
    errorIds: ['internalServerError'],
    locale: 'en-US',
};
// There is no request associated with this step


// ============================ "Step" Under Review ===========================

export const mockPendingResponse: PendingResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'pending',
    statusUrl: '',
    segment: 'student',
    subSegment: null,
    locale: 'en-US',
    maxReviewTime: '2_HRS',
    estimatedReviewTime: undefined,
};
// There is no request associated with this step


// ======================== "Step" Doc Review Limit Exceeded =======================

export const mockDocReviewLimitExceededResponse: ErrorResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    currentStep: 'error',
    segment: 'student',
    subSegment: null,
    redirectUrl: null,
    errorIds: ['docReviewLimitExceeded'],
    locale: 'en-US',
};
// There is no request associated with this step


// ======================== "Step" SSO =======================

export const mockSSOResponse: SSOResponse = {
    verificationId: '5a3bffd5e4b05f0b878a9762',
    loginUrl: 'loginUrl',
    cancelUrl: 'cancelUrl',
    currentStep: 'sso',
    segment: 'student',
    subSegment: null,
    locale: 'en-US',
};
// There is no request associated with this step

// ======================== "Step" SMSLoop =======================

export const mockSMSLoopResponse: SMSLoopResponse = {
    verificationId: '5e2f0bd2d7121162bb849f17',
    submissionUrl: '',
    currentStep: 'smsLoop',
    segment: 'student',
    subSegment: null,
    locale: 'en-US',
};

export const mockEmailLoopResponse: EmailLoopResponse = {
    verificationId: '5e2f0bd2d7121162bb849f17',
    submissionUrl: '',
    currentStep: 'emailLoop',
    segment: 'student',
    subSegment: null,
    locale: 'en-US',
    canResendEmailLoop: false,
    cancelUrl: '',
};

export const mockCancelEmailLoopResponse: DocUploadResponse = {
    verificationId: '5e2f0bd2d7121162bb849f17',
    submissionUrl: '',
    currentStep: 'docUpload',
    segment: 'student',
    subSegment: null,
    locale: 'en-US',
};

export const allMockedResponses: VerificationStepMap<AllResponseTypes> = {
    collectStudentPersonalInfo: mockStudentPersonalInfoResponse,
    collectTeacherPersonalInfo: mockTeacherPersonalInfoResponse,
    collectMemberPersonalInfo: mockMemberPersonalInfoResponse,
    docUpload: mockDocUploadVerificationResponse,
    success: mockSuccessResponse,
    error: mockErrorResponse,
    pending: mockPendingResponse,
    docReviewLimitExceeded: mockDocReviewLimitExceededResponse,
    collectMilitaryStatus: mockMilitaryStatusResponse,
    collectActiveMilitaryPersonalInfo: mockActiveMilitaryPersonalInfoResponse,
    collectInactiveMilitaryPersonalInfo: mockInactiveMilitaryPersonalInfoResponse,
    collectSeniorPersonalInfo: mockSeniorPersonalInfoResponse,
    collectFirstResponderPersonalInfo: mockFirstResponderPersonalInfoResponse,
    collectEmployeePersonalInfo: mockEmploymentPersonalInfoResponse,
    collectDriverLicensePersonalInfo: mockDriverLicensePersonalInfoResponse,
    collectGeneralIdentityPersonalInfo: mockGeneralIdentityPersonalInfoResponse,
    collectHybridIdentityPersonalInfo: mockHybridIdentityPersonalInfoResponse,
    collectLicensedProfessionalPersonalInfo: mockLicensedProfessionalPersonalInfoResponse,
    collectMoverPersonalInfo: mockMoverPersonalInfoResponse,
    sso: mockSSOResponse,
    smsLoop: mockSMSLoopResponse,
    emailLoop: mockEmailLoopResponse,
    cancelEmailLoop: mockCancelEmailLoopResponse,
    collectSocialSecurityNumber: mockSocialSecurityResponse,
    collectAgePersonalInfo: mockAgePersonalInfoResponse,
    collectMedicalProfessionalPersonalInfo: mockMedicalProfessionalPersonalInfoResponse,
    cancelSocialSecurityNumber: {} as AllResponseTypes,
};

// ======================= Student Theme =======================
export const mockInternationalStudentTheme: ProgramTheme = {
    isTestMode: true,
    isSmsNotifierConfigured: false,
    intl: {
        locale: 'en-US',
        messages: {
            errorId: {
                verificationLimitExceeded: 'exceeded',
                noRemainingRewardCodes: 'no codes',
            },
            lowRewardPool: null,
            companyName: 'sheerid',
            step: {
                personalInfo: {
                    title: 'Unlock this Student-Only Offer',
                },
                docUpload: {
                    title: 'We need your ID & class schedule',
                    subtitle: 'Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.',
                },
                success: {
                    title: 'Success! You\'ve been verified',
                    subtitle: 'Here is your personal coupon code. It is unique to you and can only be used once.',
                    redirectUrl: null,
                    redirectButtonText: 'Use code now',
                },
                error: {
                    errorId: {
                        verificationLimitExceeded: {
                            title: 'exceeded',
                        },
                        noRemainingRewardCodes: {
                            title: 'no codes',
                            buttonText: 'find offer',
                        },
                        expiredProgram: {
                            title: 'no codes',
                            buttonText: 'find offer',
                        },
                        inactiveProgram: {
                            title: 'no codes',
                            buttonText: 'find offer',
                        },
                    },
                },
            },
        },
    },
    customCss: '/* \r\n\r\n**********************\r\nATTENTION - This configuration is written by sheerid-web-apps, my.sheerid.com\r\n**********************\r\n\r\n */',
    logoUrl: null,
    openOrgSearchEnabled: false,
    config: {
        countries: ['US', 'CA', 'GB'],
        locales: ['en-US'],
        maxReviewTime: '20_MIN',
        estimatedReviewTime: 'A_FEW_MINUTES',
        marketConsent: {
            enabled: null,
            required: null,
            message: null,
        },
        customMetadata: {
            enabled: false,
            keys: [],
        },
        customFaqLink: null,
        orgSearchUrl: 'https://orgsearch-preview.sheerid.net/rest/organization/search?tags=TITLE4%2CHEI&country=US%2CCA%2CGB%2CJP%2CMX%2CFR%2CDE%2CZW&type=UNIVERSITY&name=',
        orgTypes: ['UNIVERSITY'],
        excludedOrganizationIds: [],
        offerType: 'staticCode',
    },
};

// ================= RequestOrganizationSearch ===============
export const mockParamResults = {
    dynamic: [
        { id: 87987980, name: 'Kingswood School' },
        { id: 87987981, name: 'Mark Antony High' },
    ],
    fixed: [
        { id: 87987982, name: 'Samuels University of varius matters' },
        { id: 87987983, name: 'Adrianos High' },
    ],
};
export const mockRequestOrganizationService: RequestOrganizationService = {
    viewModel: {
        countryChoice: { value: 'CA', label: 'Canada (exclues Québec)' },
        localeChoice: { value: 'en-US', label: '' },
        orgDomain: '',
        orgName: '',
        firstName: '',
        lastName: '',
        email: '',
        displayResults: false,
        searchByOrgName: false,
        completeRequest: false,
        eligibleOrgs: [
            { id: 87987980, name: 'Kingswood School' },
            { id: 87987981, name: 'Mark Antony High' },
        ],
        ineligibleOrgs: [
            { id: 87987982, name: 'Samuels University of varius matters' },
            { id: 87987983, name: 'Adrianos High' },
        ],
        orgCountryError: null,
        orgDomainError: null,
        orgNameError: null,
        firstNameError: null,
        lastNameError: null,
        emailError: null,
        isSearching: false,
    },
    programTheme: mockInternationalStudentTheme,
    programId: '5e5e9a4e8c7bc76b2d1f73d6',
    submitted: false,
    locale: 'en-US',
    isLoading: 1,
    isInitialized: true,
    error: '',
    messages: {
        title: '',
        description: '',
        noCountry: '',
        searchByOrgName: '',
        completeRequest: '',
        submit: '',
        eligibleOrgs: '',
        ineligibleOrgs: '',
        none: '',
        changeLanguage: '',
        poweredBy: '',
        country: '',
        copied: '',
        fields: {
            countryPlaceholder: '',
            domainLabel: '',
            domainPlaceholder: '',
            orgNameLabel: '',
            orgNamePlaceholder: '',
        },
        step: {
            success: {
                title: '',
                description: '',
            },
            error: {
                title: '',
                description: '',
                seeingProblem: '',
                contactUs: '',
            },
        },
        errorId: {
            invalidCountry: '',
            invalidEmail: '',
            invalidFirstName: '',
            invalidLastName: '',
            requiredField: '',
            invalidField: '',
            invalidUrl: '',
            tooManyResults: '',
        },
        faq: '',
        countries: enUSMessages.defaultMessages.countries,
        locales: enUSMessages.defaultMessages.locales,
    },
};
