/**
 * TODO - preamble
 */
import {
    getNewVerificationRequestUrl, getProgramThemeUrl, getNewSmsCodeResendUrl, getNewEmailCodeResendUrl, getInstallPageUrl,
} from './ApiUrls';
import { getRouteOverride, getOverriddenMock } from './TestingRouteOverrides';
import {
    PostJson, PostFiles, GetJson, DeleteJson, GetResponse,
} from './Network';
import { fetchExistingVerificationRequest } from './VerificationApiClient/fetchExistingVerificationRequest';

import { logger } from '../utils/logger/logger';

import {
    DatabaseId,
    DocUploadResponse,
    ErrorResponse,
    NewVerificationRequest,
    StudentPersonalInfoViewModel,
    StudentPersonalInfoRequest,
    ViewModel,
    VerificationResponse,
    VerificationStep,
    MockStep,
    ProgramTheme,
    DocUploadViewModel,
    DocUploadRequest,
    SeniorPersonalInfoViewModel,
    SeniorPersonalInfoRequest,
    TeacherPersonalInfoRequest,
    TeacherPersonalInfoViewModel,
    MemberPersonalInfoRequest,
    MemberPersonalInfoViewModel,
    MilitaryStatusRequest,
    ActiveMilitaryPersonalInfoViewModel,
    ActiveMilitaryPersonalInfoRequest,
    InactiveMilitaryPersonalInfoViewModel,
    InactiveMilitaryPersonalInfoRequest,
    SSOResponse,
    FirstResponderPersonalInfoRequest,
    FirstResponderPersonalInfoViewModel,
    MedicalProfessionalPersonalInfoViewModel,
    MedicalProfessionalPersonalInfoRequest,
    EmploymentPersonalInfoViewModel,
    EmploymentPersonalInfoRequest,
    SMSLoopViewModel,
    SMSLoopVerificationRequest,
    AgePersonalInfoRequest,
    AgePersonalInfoViewModel,
    SocialSecurityResponse,
    SocialSecurityViewModel,
    SocialSecurityRequest,
    PersonalInfoResponse,
    NetworkErrorId,
    EmailLoopViewModel,
    EmailLoopVerificationRequest,
    DriverLicensePersonalInfoViewModel,
    DriverLicensePersonalInfoRequest,
    GeneralIdentityPersonalInfoViewModel,
    GeneralIdentityPersonalInfoRequest,
    HybridIdentityPersonalInfoViewModel,
    HybridIdentityPersonalInfoRequest,
    LicensedProfessionalPersonalInfoViewModel,
    LicensedProfessionalPersonalInfoRequest,
    MoverPersonalInfoRequest,
    MoverPersonalInfoViewModel,
    EmailLoopResponse,
    Locale,
} from '../types/types';

import { VerificationStepsEnum } from '../types/runtimeTypes';
import { assertValidVerificationStepName, assertValidProgramId, assertValidLocale } from '../types/assertions';
import { getVerificationIdFromQueryString } from '../utils/routing/Url';
import { getOptions } from '../../options/options';
import { GetEmptyTheme } from '../types/empties';
import { DEFAULT_LOCALE } from '../../constants';
import { resolveTrackingId } from '../conversion/conversion';
import { getLocaleSafely } from '../intl/intl';

/**
 * Initiate a new verification attempt with the SheerID REST service.
 *
 * @param programId Your programId from my.sheerid.com
 * @param trackingId Conversion trackingId to associate with this verification attempt.
 */
async function fetchNewVerificationRequest(
    programId: DatabaseId,
    trackingId: string = undefined,
    forceNewVerificationRequest = false,
): Promise<VerificationResponse> {
    const resolvedTrackingId = resolveTrackingId(trackingId);

    try {
        let response;

        assertValidProgramId(programId);

        const windowQueryString = window.location.search;
        let verificationId;

        if (!forceNewVerificationRequest) {
            verificationId = getOptions().verificationId || getVerificationIdFromQueryString(windowQueryString);
        }

        if (verificationId) {
            logger.info('fetchNewVerificationRequest: Calling for existing verification request');
            response = fetchExistingVerificationRequest(verificationId);
        }

        if (!verificationId || response.currentStep === VerificationStepsEnum.error) {
            const url: string = getNewVerificationRequestUrl();
            const requestBody: NewVerificationRequest = {
                programId,
            };

            if (resolvedTrackingId) {
                logger.info(`Adding trackingId ${resolvedTrackingId}`);
                requestBody.trackingId = resolvedTrackingId;
            }

            const installPageUrl = getInstallPageUrl();
            if (installPageUrl) {
                logger.info(`Adding installPageUrl ${installPageUrl}`);
                requestBody.installPageUrl = installPageUrl;
            }

            response = await PostJson(url, requestBody) as VerificationResponse;
        }

        const overriddenStep: MockStep = getRouteOverride();
        const mockResponse: VerificationResponse = await getOverriddenMock(overriddenStep, response);

        if (mockResponse) {
            return mockResponse;
        }

        return response;
    } catch (e) {
        logger.error(e, 'fetchNewVerificationRequest');
        throw e;
    }
}

async function fetchProgramTheme(programId: DatabaseId, locale?: Locale): Promise<ProgramTheme> {
    if (!getOptions().doFetchTheme) {
        return GetEmptyTheme();
    }
    logger.info('fetchProgramTheme: Calling back-end for program theme');

    try {
        assertValidProgramId(programId);

        if (locale) assertValidLocale(locale);
        const cleanLocale = locale || getLocaleSafely();

        let url: string;
        if (cleanLocale !== DEFAULT_LOCALE) {
            url = getProgramThemeUrl(programId, cleanLocale);
        } else {
            url = getProgramThemeUrl(programId);
        }
        return await GetJson(url) as ProgramTheme;
    } catch (e) {
        logger.error(e, 'fetchProgramTheme');
        throw e;
    }
}

async function getResendNewSmsCode(verificationId: DatabaseId): Promise<any> {
    logger.info('Resending new sms code');

    try {
        const url = getNewSmsCodeResendUrl(verificationId);
        const response = await GetResponse(url);
        return response;
    } catch (e) {
        logger.error(e, 'getResendNewSmsCode');
    }
}

async function getResendNewEmailCode(verificationId: DatabaseId): Promise<any> {
    logger.info('Resending new email code');

    try {
        const url = getNewEmailCodeResendUrl(verificationId);
        const response = await GetResponse(url);
        return response;
    } catch (e) {
        logger.error(e, 'getResendNewEmailCode');
    }
}

export function getMockVerificationRequestErrorResponse(errorId: NetworkErrorId): VerificationResponse {
    return {
        verificationId: null,
        currentStep: 'error',
        errorIds: [errorId],
        segment: null,
        subSegment: null,
        locale: 'en-US',
    };
}

// StudentPersonalInfoViewModel contains multiple keys we don't want so we can't return the full viewModel as the request body
const studentViewModelToRequest = (viewModel: StudentPersonalInfoViewModel): StudentPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

// TeacherPersonalInfoViewModel contains multiple keys we don't want so we can't return the full viewModel as the request body
const teacherViewModelToRequest = (viewModel: TeacherPersonalInfoViewModel): TeacherPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    postalCode: viewModel.postalCode,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const memberViewModelToRequest = (viewModel: MemberPersonalInfoViewModel): MemberPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    memberId: viewModel.memberId,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const seniorViewModelToRequest = (viewModel: SeniorPersonalInfoViewModel): SeniorPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    email: viewModel.email,
    postalCode: viewModel.postalCode,
    phoneNumber: viewModel.phoneNumber,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const ageViewModelToRequest = (viewModel: AgePersonalInfoViewModel): AgePersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    email: viewModel.email,
    postalCode: viewModel.postalCode !== '' ? viewModel.postalCode : undefined,
    phoneNumber: viewModel.phoneNumber !== '' ? viewModel.phoneNumber : undefined,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
    metadata: viewModel.metadata,
});

// ActiveMilitaryPersonalInfoViewModel contains multiple keys we don't want so we can't return the full viewModel as the request body
const militaryStatusViewModelToRequest = (viewModel: ActiveMilitaryPersonalInfoViewModel): MilitaryStatusRequest => ({
    status: viewModel.status,
});

// ActiveMilitaryPersonalInfoViewModel contains 'status' which we don't want in the request so we can't return the full viewModel
const activeMilitaryViewModelToRequest = (viewModel: ActiveMilitaryPersonalInfoViewModel): ActiveMilitaryPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
    metadata: viewModel.metadata,
});

// InactiveMilitaryPersonalInfoViewModel contains 'status' which we don't want in the request so we can't return the full viewModel
const inactiveMilitaryViewModelToRequest = (viewModel: InactiveMilitaryPersonalInfoViewModel): InactiveMilitaryPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    dischargeDate: viewModel.dischargeDate,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
    metadata: viewModel.metadata,
});

export const firstResponderViewModelToRequest = (viewModel: FirstResponderPersonalInfoViewModel): FirstResponderPersonalInfoRequest => {
    const request: FirstResponderPersonalInfoRequest = {
        firstName: viewModel.firstName,
        lastName: viewModel.lastName,
        birthDate: viewModel.birthDate,
        email: viewModel.email,
        status: viewModel.status,
        postalCode: viewModel.postalCode,
        phoneNumber: viewModel.phoneNumber,
        organization: viewModel.organization,
        deviceFingerprintHash: viewModel.deviceFingerprintHash,
        locale: viewModel.localeChoice.value,
        country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
        metadata: viewModel.metadata,
    };

    const hasStatuses = viewModel.statuses && Array.isArray(viewModel.statuses) && viewModel.statuses.length > 0;
    if (hasStatuses) {
        request.statuses = viewModel.statuses;
        delete request.status; // API prefers the statuses field only
    }

    if (!(viewModel.status || hasStatuses)) {
        logger.error('Either status or statuses must be provided on First Responder view model');
    }

    return request;
};

const medicalProfessionalViewModelToRequest = (viewModel: MedicalProfessionalPersonalInfoViewModel): MedicalProfessionalPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    birthDate: viewModel.birthDate,
    status: viewModel.status,
    email: viewModel.email,
    postalCode: viewModel.postalCode,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
    country: viewModel.countryChoice ? viewModel.countryChoice.value : undefined,
});

const employmentViewModelToRequest = (viewModel: EmploymentPersonalInfoViewModel): EmploymentPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    address1: viewModel.address1,
    city: viewModel.city,
    state: viewModel.state,
    postalCode: viewModel.postalCode,
    phoneNumber: viewModel.phoneNumber,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const driverLicenseViewModelToRequest = (viewModel: DriverLicensePersonalInfoViewModel): DriverLicensePersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    state: viewModel.state,
    driverLicenseNumber: viewModel.driverLicenseNumber,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const generalIdentityViewModelToRequest = (viewModel: GeneralIdentityPersonalInfoViewModel): GeneralIdentityPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    birthDate: viewModel.birthDate,
    address1: viewModel.address1,
    city: viewModel.city,
    state: viewModel.state,
    postalCode: viewModel.postalCode,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const hybridIdentityViewModelToRequest = (viewModel: HybridIdentityPersonalInfoViewModel): HybridIdentityPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    birthDate: viewModel.birthDate,
    address1: viewModel.address1,
    city: viewModel.city,
    state: viewModel.state,
    postalCode: viewModel.postalCode,
    driverLicenseNumber: viewModel.driverLicenseNumber,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const licensedProfessionalViewModelToRequest = (viewModel: LicensedProfessionalPersonalInfoViewModel): LicensedProfessionalPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    phoneNumber: viewModel.phoneNumber,
    birthDate: viewModel.birthDate,
    postalCode: viewModel.postalCode,
    statuses: viewModel.statuses,
    organization: viewModel.organization,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const moverViewModelToRequest = (viewModel: MoverPersonalInfoViewModel): MoverPersonalInfoRequest => ({
    firstName: viewModel.firstName,
    lastName: viewModel.lastName,
    email: viewModel.email,
    statuses: viewModel.statuses,
    address1: viewModel.address1,
    postalCode: viewModel.postalCode,
    phoneNumber: viewModel.phoneNumber,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
    locale: viewModel.localeChoice.value,
    metadata: viewModel.metadata,
});

const smsLoopViewModelToRequest = (viewModel: SMSLoopViewModel): SMSLoopVerificationRequest => ({
    smsCode: viewModel.smsCode,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
});

const emailLoopViewModelToRequest = (viewModel: EmailLoopViewModel): EmailLoopVerificationRequest => ({
    emailToken: viewModel.emailToken,
    deviceFingerprintHash: viewModel.deviceFingerprintHash,
});

const socialSecurityViewModelToRequest = (viewModel: SocialSecurityViewModel): SocialSecurityRequest => {
    if (typeof viewModel.socialSecurityNumber !== 'string' || viewModel.socialSecurityNumber.length < 1) {
        throw new Error('Poorly formed social security number, unable to submit');
    }

    const socialSecurityNumber = Number.parseInt(viewModel.socialSecurityNumber, 10); // Server wants an integer
    const requestBody: SocialSecurityRequest = { socialSecurityNumber };
    return requestBody;
};

const stepToRequest = {
    collectStudentPersonalInfo: studentViewModelToRequest,
    collectSeniorPersonalInfo: seniorViewModelToRequest,
    collectAgePersonalInfo: ageViewModelToRequest,
    collectMilitaryStatus: militaryStatusViewModelToRequest,
    collectActiveMilitaryPersonalInfo: activeMilitaryViewModelToRequest,
    collectInactiveMilitaryPersonalInfo: inactiveMilitaryViewModelToRequest,
    collectFirstResponderPersonalInfo: firstResponderViewModelToRequest,
    collectMedicalProfessionalPersonalInfo: medicalProfessionalViewModelToRequest,
    collectMemberPersonalInfo: memberViewModelToRequest,
    collectEmployeePersonalInfo: employmentViewModelToRequest,
    collectTeacherPersonalInfo: teacherViewModelToRequest,
    collectSocialSecurityNumber: socialSecurityViewModelToRequest,
    collectDriverLicensePersonalInfo: driverLicenseViewModelToRequest,
    collectGeneralIdentityPersonalInfo: generalIdentityViewModelToRequest,
    collectHybridIdentityPersonalInfo: hybridIdentityViewModelToRequest,
    collectLicensedProfessionalPersonalInfo: licensedProfessionalViewModelToRequest,
    collectMoverPersonalInfo: moverViewModelToRequest,
    smsLoop: smsLoopViewModelToRequest,
    emailLoop: emailLoopViewModelToRequest,
    cancelSocialSecurityNumber: null,
    sso: null,
    docUpload: null,
};

async function submitFromVerificationStep(
    step: VerificationStep,
    previousResponse: VerificationResponse,
    viewModel: ViewModel,
): Promise<VerificationResponse | ErrorResponse> {
    let response;
    const {
        docUpload, cancelSocialSecurityNumber, sso, cancelEmailLoop,
    } = VerificationStepsEnum;
    try {
        if (step === docUpload) {
            const requestBody: DocUploadRequest = viewModel as DocUploadViewModel;
            const { file1, file2, file3 } = requestBody;
            response = await PostFiles((previousResponse as DocUploadResponse).submissionUrl, [file1, file2, file3]);
            return response;
        }
        if (step === cancelSocialSecurityNumber || step === sso) {
            response = await DeleteJson((previousResponse as SSOResponse | SocialSecurityResponse).cancelUrl);
            return response;
        }
        if (step === cancelEmailLoop) {
            response = await DeleteJson((previousResponse as EmailLoopResponse).cancelUrl);
            return response;
        }
        const requestBody = stepToRequest[step](viewModel);
        response = await PostJson((previousResponse as PersonalInfoResponse).submissionUrl, requestBody);

        return response;
    } catch (e) {
        logger.error(e, 'submitFromVerificationStep');
        // At least show the error step...
        return getUnrecoverableErrorStep();
    }
}

function getUnrecoverableErrorStep(): ErrorResponse {
    // TODO build an empty error step response with "unknown error" or some other appropriate error id.
    const response: ErrorResponse = {
        verificationId: '',
        currentStep: 'error',
        segment: null,
        subSegment: null,
        redirectUrl: null,
        locale: 'en-US',
        errorIds: ['unknownError'],
    };
    return response;
}

async function submitStep(
    stepName: VerificationStep,
    previousResponse: VerificationResponse,
    viewModel: ViewModel,
): Promise<VerificationResponse | ErrorResponse> {
    assertValidVerificationStepName(stepName);
    if (VerificationStepsEnum[stepName]) {
        return submitFromVerificationStep(stepName, previousResponse, viewModel);
    }
    return Promise.reject(new Error(`Unknown step ${stepName}`));
}

export const VerificationApiClient = {
    fetchNewVerificationRequest,
    fetchExistingVerificationRequest,
    fetchProgramTheme,
    getResendNewSmsCode,
    getResendNewEmailCode,
    submitStep,
};
