import { useCombobox, UseComboboxStateChange } from 'downshift';
import { Organization } from '../../../lib/types/types';

export const handleStateChange = (changes: UseComboboxStateChange<Organization>, onChange: Function, openOrgSearchEnabled: boolean) => {
    const keyUp = changes.hasOwnProperty('inputValue') && (changes.type === useCombobox.stateChangeTypes.InputChange);

    if (changes.type === useCombobox.stateChangeTypes.FunctionSelectItem) {
        const { selectedItem } = changes;
        onChange(selectedItem);
    } else if (keyUp) {
        const selectedItem: Organization = {
            id: 0,
            name: changes.inputValue,
        };
        onChange(selectedItem);
    } else if (changes.type === useCombobox.stateChangeTypes.InputBlur) {
        if (!openOrgSearchEnabled) {
            onChange({
                id: undefined,
                name: '',
            });
        }
    }
};
